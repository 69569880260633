import { useQuery } from "@tanstack/react-query";
import { CampaignServiceImpl } from "features/campaign/domain/services/campaignService";
import { useState } from "react";

const campaignService = new CampaignServiceImpl();

export const useKroFee = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["kroFee"],
    queryFn: campaignService.getKroFee,
  });

  return { data, isLoading, error };
};

export const useKroFeeCalculation = (feePercentage: number) => {
  const [amountToReceive, setAmountToReceive] = useState<string>("0");
  const [serviceFee, setServiceFee] = useState<number>(0);

  const handleTargetAmountChange = (amount: number) => {
    
    if (!isNaN(amount)) {
      const fee = amount * (feePercentage / 100);
      const amountWithoutFee = amount - fee;
      setAmountToReceive(amountWithoutFee.toString());
      setServiceFee(fee);
      return;
    }
    setServiceFee(0.0);
    setAmountToReceive("0");
  };



  return {
    amountToReceive,
    serviceFee,
    handleTargetAmountChange,
  };
};
