import { FC, useEffect, useState } from "react";

const KroCarousel: FC<{
  images: string[];
}> = ({ images }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isLoading) {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1,
        );
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [images.length, isLoading]);

  return (
    <div className="relative h-[315px] w-full overflow-hidden rounded-3xl border border-[#E1E4EA] bg-[#022928] shadow-[0px_1px_2px_0px_#0A0D1408]">
      <div className="relative h-full w-full">
        <img
          src={images[currentImageIndex]}
          alt="slider"
          onLoadStart={() => setIsLoading(true)}
          onLoad={() => setIsLoading(false)}
          className={`h-full w-full object-cover transition-opacity duration-500 ${isLoading ? "opacity-0" : "opacity-100"}`}
        />
        {isLoading && (
          <div className="absolute bottom-0 left-0 right-0 top-0 flex h-full w-full animate-pulse bg-brand-dark_green opacity-10"></div>
        )}
      </div>

      <div className="absolute bottom-0 mb-[23px] flex w-full items-center justify-center gap-[7px]">
        {images.map((_, index) => (
          <div
            key={index}
            className={`h-[5px] w-full max-w-[39px] rounded-[10px] ${currentImageIndex === index ? "bg-[#4FBA6D]" : "bg-[#D9D9D9]"}`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default KroCarousel;
