import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Routes from "router/routes";

interface EndCampaignHeaderProps {
  openCampaignModal: () => void;
  data: CampaignModelMapper | undefined;
}

export const EndCampaignHeader: FC<EndCampaignHeaderProps> = ({
  openCampaignModal,
  data,
}) => {
  const navigate = useNavigate();

  const handleEditCampaign = () => {
    if (!data) return;
    navigate(Routes.EDIT_CAMPAIGN(data?.id ?? ""), { state: data });
  };

  return (
    <div className="items-center">
      <div className="hidden items-center justify-between border-gray-300 pt-5 lg:flex">
        <button
          onClick={() => {
            if (window.history.length > 1) {
              navigate(-1);
            } else {
              navigate(Routes.MY_CAMPAIGNS);
            }
          }}
          rel="noopener noreferrer"
          className="flex items-center space-x-1 text-gray-800 hover:text-gray-600"
        >
          <img src="/assets/icons/Arrow_Back.svg" alt="Back" />
          <p className="text-body-md text-neutral-900">Back to Campaigns</p>
        </button>

        <div className="flex space-x-4">
          <button
            onClick={data?.status === "ended" ? () => {} : handleEditCampaign}
            className={`h-[37px] w-[120px] justify-items-center rounded-3xl border border-neutral-200 bg-neutral-50 px-4 py-2 text-body-sm text-[#0F0F0F] hover:bg-neutral-200 ${data?.status === "ended" ? "cursor-not-allowed opacity-50" : ""}`}
          >
            Edit
          </button>

          <button
            onClick={data?.status === "ended" ? () => {} : openCampaignModal}
            className={`h-[37px] w-[150px] justify-items-center rounded-3xl border border-negative-600 bg-negative-50 px-4 py-2 text-body-sm text-red-600 hover:bg-red-100 ${data?.status === "ended" ? "cursor-not-allowed opacity-50" : ""}`}
          >
            End Campaign
          </button>
        </div>
      </div>
    </div>
  );
};
