import { useState, useRef } from 'react';
import axios from 'axios';

export interface UseCloudinaryVideoUpload {
  uploadProgress: number;
  uploadUrl: string | null;
  uploadError: string | null;
  uploadVideo: (file: File) => Promise<void>;
  cancelUpload: () => void;
}

const useCloudinaryVideoUpload = (onUploadComplete: (url: string) => void): UseCloudinaryVideoUpload => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadUrl, setUploadUrl] = useState<string | null>(null);
  // const [deleteToken, setDeleteToken] = useState<string | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || '';
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || '';

  const uploadVideo = async (file: File): Promise<void> => {
    if (!file) {
      setUploadError('No file selected.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);

    abortControllerRef.current = new AbortController();

    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/video/upload`,
        formData,
        {
          signal: abortControllerRef.current.signal,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        }
      );

      setUploadUrl(response.data.secure_url);
      onUploadComplete(response.data.secure_url);
      // setDeleteToken(response.data.delete_token); 
      setUploadError(null);
    } catch (error) {
      if (axios.isCancel(error)) {
        setUploadError('Upload canceled.');
      } else {
        setUploadError('Upload failed.');
        console.error('Error uploading the video:', error);
      }
    }
  };

  const cancelUpload = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  return { uploadProgress, uploadUrl, uploadError, uploadVideo, cancelUpload };
};

export default useCloudinaryVideoUpload;
