import { createAvatar, Options } from "@dicebear/core";
import { initials, adventurerNeutral } from "@dicebear/collection";




// interface UseDiceBearProps {
//     style?: typeof initials;
//     seed: string;
//     size?: number;
//     options?: Options;
// }

// const useDiceBear = ({ style = initials, seed, size = 40, options }: UseDiceBearProps) => {   
//     const avatar = useMemo(() => {
//         return createAvatar(style, {
//             seed: seed,
//             size: size,
//             ...options,
//         }).toDataUri();
//     }, [seed, size, style, options]);

//     return avatar;
// };

// export default useDiceBear;


interface UseDiceBearProps {
    style?: typeof initials;
    seed: string;
    size?: number;
    options?: Options;
}

const useDiceBear = ({ style = adventurerNeutral, seed, size = 40, options }: UseDiceBearProps) => {

   
    const avatar = createAvatar(style, {
        seed: seed,
        size: size,
        ...options,
    }).toDataUri();

    return avatar;
};

export default useDiceBear;
