import { useQuery } from "@tanstack/react-query";
import { DonationFeesResponse } from "features/campaign/domain/models/donationFeesResponse";
import { CampaignServiceImpl } from "features/campaign/domain/services/campaignService";
import { useState } from "react";

const campaignService = new CampaignServiceImpl();

export const useFetchDonationFees = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["kroDonationFee"],
    queryFn: campaignService.fetchDonationFees,
  });

  return { data, isLoading, error };
};
export const useKroDonationFeeCalculation = (
  feesResponse: DonationFeesResponse | undefined,
) => {
  const [amountToReceive, setAmountToReceive] = useState<string>("0");
  const [serviceFee, setServiceFee] = useState<number>(0);

  const handleDonationAmountChange = (amount: number) => {
    if (!isNaN(amount)) {
      const baseFeeRate = parseFloat(feesResponse?.baseFeeRate ?? "0") || 0;
      const additionalFeeThreshold =
        parseFloat(feesResponse?.additionalFeeThreshold ?? "0") || 0;
      const feeCap = parseFloat(feesResponse?.feeCap ?? "0") || 0;

      let fee = amount * baseFeeRate;

      if (amount >= additionalFeeThreshold) {
        fee += 100;
      }

      if (fee > feeCap) {
        fee = feeCap;
      }
      setServiceFee(fee);
      setAmountToReceive(amount.toString());
      return;
    }
    setServiceFee(0);
    setAmountToReceive("0");
  };

  return {
    amountToReceive,
    serviceFee,
    handleDonationAmountChange,
  };
};
