import React from "react";

export const SocialMediaHolder = (props: SocialMediaHolderProps) => {
  return (
    <button
      className={`flex flex-col items-center rounded ${props.className}`}
      onClick={props.onClick}
    >
      <img className="" src={props.imgSrc} alt={props.medium} />
      <span className="mt-2 text-body-md text-sub-600">{props.medium}</span>
    </button>
  );
};

interface SocialMediaHolderProps {
  imgSrc: string;
  medium: string;
  className?: string;
  onClick: () => void;
}
