// import { FC, useState } from "react";
// import { fundraiserTargetForm } from "../../forms/fudraiserTargetForm";
// import CustomDatePicker from "features/components/display/kroDatePicker";
// import KroTooltip from "features/components/display/kroToolTip";
// import moment from "moment";
// import Validation from "common/utils/validation";
// import { FieldApi } from "@tanstack/react-form";

// interface DurationSelectorProps {
//   form: fundraiserTargetForm;
// }

// const DurationSelector: FC<DurationSelectorProps> = ({ form }) => {
//   const [selectedDuration, setSelectedDuration] = useState<string | null>(null);
//   const [, setCustomDate] = useState<string | null>(null);
//   const [isCustomSelected, setIsCustomSelected] = useState<boolean>(false);

//   const durations = [
//     "1 Week",
//     "2 Weeks",
//     "3 Weeks",
//     "1 Month",
//     "3 Months",
//     "6 Months",
//     "Custom",
//   ];

//   const durationMapping: {
//     [key: string]: { value: number; unit: "days" | "months" };
//   } = {
//     "1 Week": { value: 7, unit: "days" },
//     "2 Weeks": { value: 14, unit: "days" },
//     "3 Weeks": { value: 21, unit: "days" },
//     "1 Month": { value: 1, unit: "months" },
//     "3 Months": { value: 3, unit: "months" },
//     "6 Months": { value: 6, unit: "months" },
//   };

//   const handleDurationClick = (duration: string, field: FieldApi<any, any>) => {
//     if (duration === "Custom") {
//       setIsCustomSelected(true);
//       setSelectedDuration("Custom");
//       setCustomDate(null);
//     } else {
//       setIsCustomSelected(false);
//       setSelectedDuration(duration);
//       setCustomDate(null);

//       const { value, unit } = durationMapping[duration];
//       const endDate = moment().add(value, unit).format("YYYY-MM-DD");
//       field.handleChange(endDate);
//     }
//   };

//   const handleCustomDateSelected = (
//     date: moment.Moment | null,
//     field: FieldApi<any, any>,
//   ) => {
//     if (date) {
//       const formattedDate = date.format("YYYY-MM-DD");
//       setCustomDate(formattedDate);
//       field.handleChange(formattedDate);
//     } else {
//       setCustomDate(null);
//       field.handleChange(null);
//     }
//   };

//   const getTooltipTitle = (duration: string) => {
//     if (duration === "Custom") return "Select a custom date";
//     const { value, unit } = durationMapping[duration];
//     const endDate = moment().add(value, unit).format("MMMM Do YYYY");
//     return `${endDate}`;
//   };

//   return (
//     <form.Field
//       name="endDate"
//       validators={{
//         onChange: ({ value }) => Validation.required(value),
//       }}
//       children={(field) => {
//         const errorMessage = field.state.meta.errors.join(", ");

//         return (
//           <div id={field.name}>
//             <p className="mb-3 w-full text-left text-body-md leading-22 tracking-ultralTight">
//               How long should the fundraiser run for?{" "}
//               {errorMessage && (
//                 <span className="text-left italic text-red-500">
//                   {" " + errorMessage}
//                 </span>
//               )}
//             </p>
//             <div className="mb-7 flex flex-wrap gap-2">
//               {durations.map((duration) =>
//                 duration === "Custom" ? (
//                   <div
//                     key={duration}
//                     className={`h-11 min-w-[107px] cursor-pointer rounded-[32px] border px-2 py-3 text-center text-body-md leading-22 tracking-ultralTight ${
//                       selectedDuration === duration || isCustomSelected
//                         ? "border-brand-dark_green bg-[#4FBA6D1A] font-medium"
//                         : "border-neutral-200 bg-neutral-50"
//                     }`}
//                     onClick={() => handleDurationClick(duration, field)}
//                   >
//                     {isCustomSelected ? (
//                       <CustomDatePicker
//                         autoOpen={true}
//                         onSelect={(value) =>
//                           handleCustomDateSelected(value, field)
//                         }
//                       />
//                     ) : (
//                       duration
//                     )}
//                   </div>
//                 ) : (
//                   <KroTooltip
//                     key={duration}
//                     subtitle={getTooltipTitle(duration)}
//                   >
//                     <button
//                       type="button"
//                       className={`h-11 min-w-[107px] transform rounded-[32px] border px-2 py-3 text-center text-body-md leading-22 tracking-ultralTight transition duration-300 ease-in-out ${
//                         selectedDuration === duration
//                           ? "scale-105 border-brand-dark_green bg-[#4FBA6D1A] font-medium"
//                           : "border-neutral-200 bg-neutral-50"
//                       } hover:scale-105 hover:border-brand-dark_green hover:bg-[#4FBA6D1A] active:scale-95`}
//                       onClick={() => handleDurationClick(duration, field)}
//                     >
//                       {duration}
//                     </button>
//                   </KroTooltip>
//                 ),
//               )}
//             </div>
//           </div>
//         );
//       }}
//     />
//   );
// };

// export default DurationSelector;

import { FC, useEffect, useState } from "react";
import { fundraiserTargetForm } from "../../forms/fudraiserTargetForm";
import CustomDatePicker from "features/components/display/kroDatePicker";
import KroTooltip from "features/components/display/kroToolTip";
import moment from "moment";
import Validation from "common/utils/validation";
import { FieldApi } from "@tanstack/react-form";
interface DurationSelectorProps {
  form: fundraiserTargetForm;
  autoOpenCalender?: boolean;
}

const DurationSelector: FC<DurationSelectorProps> = ({
  form,
  autoOpenCalender = true,
}) => {
  const [selectedDuration, setSelectedDuration] = useState<string | null>(null);
  const [, setCustomDate] = useState<string | null>(null);
  const [isCustomSelected, setIsCustomSelected] = useState<boolean>(false);

  const durations = [
    "1 Week",
    "2 Weeks",
    "3 Weeks",
    "1 Month",
    "3 Months",
    "6 Months",
    "Custom",
  ];

  const durationMapping: {
    [key: string]: { value: number; unit: "days" | "months" };
  } = {
    "1 Week": { value: 7, unit: "days" },
    "2 Weeks": { value: 14, unit: "days" },
    "3 Weeks": { value: 21, unit: "days" },
    "1 Month": { value: 1, unit: "months" },
    "3 Months": { value: 3, unit: "months" },
    "6 Months": { value: 6, unit: "months" },
  };

  useEffect(() => {
    initializeDuration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeDuration = () => {
    if (form.state.values.endDate) {
      const initialDate = moment(form.state.values.endDate);
      const now = moment();
      const diffDays = initialDate.diff(now, "days");
      const diffMonths = initialDate.diff(now, "months");

      let matchedDuration = null;

      for (const [key, { value, unit }] of Object.entries(durationMapping)) {
        if (
          (unit === "days" && value === diffDays) ||
          (unit === "months" && value === diffMonths)
        ) {
          matchedDuration = key;
          break;
        }
      }

      if (matchedDuration) {
        setSelectedDuration(matchedDuration);
        setIsCustomSelected(false);
      } else {
        setSelectedDuration("Custom");
        setIsCustomSelected(true);
        setCustomDate(form.state.values.endDate);
      }
    }
  };

  const handleDurationClick = (duration: string, field: FieldApi<any, any>) => {
    if (duration === "Custom") {
      setIsCustomSelected(true);
      setSelectedDuration("Custom");
      setCustomDate(null);
    } else {
      setIsCustomSelected(false);
      setSelectedDuration(duration);
      setCustomDate(null);

      const { value, unit } = durationMapping[duration];
      const endDate = moment().add(value, unit).format("YYYY-MM-DD");
      field.handleChange(endDate);
    }
  };

  const handleCustomDateSelected = (
    date: moment.Moment | null,
    field: FieldApi<any, any>,
  ) => {
    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      setCustomDate(formattedDate);
      field.handleChange(formattedDate);
    } else {
      setCustomDate(null);
      field.handleChange(null);
    }
  };

  const getTooltipTitle = (duration: string) => {
    if (duration === "Custom") return "Select a custom date";
    const { value, unit } = durationMapping[duration];
    const endDate = moment().add(value, unit).format("MMMM Do YYYY");
    return `${endDate}`;
  };

  return (
    <form.Field
      name="endDate"
      validators={{
        onChange: ({ value }) => Validation.required(value),
      }}
      children={(field) => {
        const errorMessage = field.state.meta.errors.join(", ");

        return (
          <div id={field.name}>
            <p className="mb-3 w-full text-left text-body-md leading-22 tracking-ultralTight">
              How long should the fundraiser run for?{" "}
              {errorMessage && (
                <span className="text-left italic text-red-500">
                  {" " + errorMessage}
                </span>
              )}
            </p>
            <div className="mb-7 flex flex-wrap gap-2">
              {durations.map((duration) =>
                duration === "Custom" ? (
                  <div
                    key={duration}
                    className={`h-11 min-w-[107px] cursor-pointer rounded-[32px] border px-2 py-3 text-center text-body-md leading-22 tracking-ultralTight ${
                      selectedDuration === duration || isCustomSelected
                        ? "border-brand-dark_green bg-[#4FBA6D1A] font-medium"
                        : "border-neutral-200 bg-neutral-50"
                    }`}
                    onClick={() => handleDurationClick(duration, field)}
                  >
                    {isCustomSelected ? (
                      <CustomDatePicker
                        value={form.state.values.endDate}
                        autoOpen={autoOpenCalender}
                        onSelect={(value) =>
                          handleCustomDateSelected(value, field)
                        }
                      />
                    ) : (
                      duration
                    )}
                  </div>
                ) : (
                  <KroTooltip
                    key={duration}
                    subtitle={getTooltipTitle(duration)}
                  >
                    <button
                      type="button"
                      className={`h-11 min-w-[107px] transform rounded-[32px] border px-2 py-3 text-center text-body-md leading-22 tracking-ultralTight transition duration-300 ease-in-out ${
                        selectedDuration === duration
                          ? "scale-105 border-brand-dark_green bg-[#4FBA6D1A] font-medium"
                          : "border-neutral-200 bg-neutral-50"
                      } hover:scale-105 hover:border-brand-dark_green hover:bg-[#4FBA6D1A] active:scale-95`}
                      onClick={() => handleDurationClick(duration, field)}
                    >
                      {duration}
                    </button>
                  </KroTooltip>
                ),
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default DurationSelector;
