const STORAGE_KEYS = {
  DONATE_FORM_DATA: 'DONATE_FORM_DATA',
  CREATE_CAMPAIGN_FORM_DATA: 'CREATE_CAMPAIGN_FORM_DATA',
};
export const setStorageItem = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getStorageItem = (key: string) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
};

export const removeStorageItem = (key: string) => {
    localStorage.removeItem(key);
};

export const clearStorageItems = (keys: string[]) => {
    keys.forEach((key) => localStorage.removeItem(key));
};

export const clearAllStorageItems = () => {
    localStorage.clear();
};

export default STORAGE_KEYS;