import React from "react";

interface CustomButtonProps {
  label: string;
  isSelected?: boolean;
  onClick?: () => void;
}

const CustomButton: React.FC<CustomButtonProps> & { Skeleton: React.FC } = ({
  label,
  isSelected,
  onClick,
}) => {
  return (
    <button
      className={`flex h-12 items-center justify-center rounded-[32px] border px-6 py-[13.4px] font-inter text-[15px] font-medium leading-24 tracking-ultralTight sm:h-[50px] sm:text-body-lg ${isSelected ? "border-black" : "border-[#E5E5E5]"} bg-neutral-50 text-[#0F0F0F] hover:bg-neutral-200`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

CustomButton.Skeleton = () => {
  return <div className="h-[51px] w-[98px] animate-pulse bg-gray-200"></div>;
};

export default CustomButton;
