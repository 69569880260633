import { Navigate, Outlet } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import CampaignTypesPage from "features/campaign/presentation/pages/campaignTypesPage";
import CampaignImageUploadPage from "features/campaign/presentation/pages/campaignImageUploadPage";
import CampaignInformationPage from "features/campaign/presentation/pages/campaignInformationPage";
import CampaignFundraiserTargetPage from "features/campaign/presentation/pages/campaignFundraiserTargetPage";
import Routes from "./routes";
import { CreateCampaignFormProvider } from "features/campaign/presentation/context/CreateCampaignFormProvider";
import KroLoadingIndicator from "features/components/loader/kroCircularIndicator";
import { lazy, Suspense } from "react";
import ErrorPage from "./errorPage";
import { OnboardingProvider } from "features/onboarding/presentation/context/OnboardingContextProvider";
import { CampaignDonateFormProvider } from "features/campaign/presentation/context/campaignDonateFormProvider";
import { VideoUploadProvider } from "features/campaign/presentation/context/uploadVideoContext";
import OrganizationStepOne from "features/onboarding/presentation/pages/organizationStepOnePage";
import OrganizationStepTwo from "features/onboarding/presentation/pages/organizationStepTwoPage";
import ProtectedRoute from "./protectedRoute";
import EndCampaignPage from "features/dashboard/presentation/pages/endCampaignPage";
import DashBoardHomePage from "features/dashboard/presentation/pages/dashboardHomePage";
import useAuthStore from "common/store/useAuthStore";
import HomePage from "features/landing/presentation/pages/homePage";
import EditCampaignPage from "features/dashboard/presentation/pages/editCampaignPage";
import { DiscoverCampaigns } from "features/landing/presentation/pages/discoverCampaignPage";
import EnterAmountPage from "features/campaign/presentation/components/campaignDonate/enterAmountPage";
import PaymentSummaryPage from "features/campaign/presentation/components/campaignDonate/paymentSummaryPage";
import DonationCompletedPage from "features/campaign/presentation/components/campaignDonate/donationSuccessPage";
import DonationFailedPage from "features/campaign/presentation/components/campaignDonate/donationFailedPage";

const OnboardingLayout = lazy(
  () => import("features/onboarding/presentation/layout"),
);
const CampaignDetailsPage = lazy(
  () => import("features/campaign/presentation/pages/campaignDetailsPage"),
);
const KroLayout = lazy(() => import("common/layout/kroLayout"));
const CampaignCreateLayout = lazy(
  () => import("features/campaign/presentation/layout"),
);
const LogInPage = lazy(
  () => import("features/onboarding/presentation/pages/logInPage"),
);
const SignUp = lazy(
  () => import("features/onboarding/presentation/pages/signUpPage"),
);

export const kroRouter = createBrowserRouter([
  {
    path: Routes.CREATE_CAMPAIGN,
    errorElement: <ErrorPage />,
    element: (
      <ProtectedRoute>
        <Suspense fallback={<KroLoadingIndicator bg="bg-white" />}>
          <CreateCampaignFormProvider>
            <VideoUploadProvider>
              <CampaignCreateLayout />
            </VideoUploadProvider>
          </CreateCampaignFormProvider>
        </Suspense>
      </ProtectedRoute>
    ),
    children: [
      {
        path: Routes.CREATE_CAMPAIGN,
        element: <CampaignTypesPage />,
      },
      {
        path: Routes.CREATE_CAMPAIGN_INFORMATION,
        element: <CampaignInformationPage />,
      },
      {
        path: Routes.CREATE_CAMPAIGN_GALLERY,
        element: <CampaignImageUploadPage />,
      },
      {
        path: Routes.CREATE_CAMPAIGN_TARGET,
        element: <CampaignFundraiserTargetPage />,
      },
    ],
  },

  {
    path: Routes.HOME,
    errorElement: <ErrorPage />,
    element: (
      <Suspense fallback={<KroLoadingIndicator bg="bg-white" />}>
        <OnboardingProvider>
          <KroLayout />
        </OnboardingProvider>
      </Suspense>
    ),
    children: [
      {
        path: Routes.HOME,
        element: <HomePage />,
      },
      {
        path: Routes.DISCOVER_CAMPAIGN,
        element: <DiscoverCampaigns />,
      },

      {
        path: Routes.MY_CAMPAIGNS,
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        children: [
          {
            path: Routes.MY_CAMPAIGNS,
            element: <DashBoardHomePage />,
          },
          {
            path: Routes.END_CAMPAIGN(":id"),
            element: <EndCampaignPage />,
          },
          {
            path: Routes.EDIT_CAMPAIGN(":id"),
            element: <EditCampaignPage />,
          },
        ],
      },
      {
        path: Routes.CAMPAIGN_DETAILS(":id"),
        element: (
          <Suspense fallback={<KroLoadingIndicator />}>
            <CampaignDonateFormProvider>
              <CampaignDetailsPage />
            </CampaignDonateFormProvider>
          </Suspense>
        ),
        children: [],
      },
      {
        path: Routes.ENTER_AMOUNT(":id"),
        element: (
          <CampaignDonateFormProvider>
            <EnterAmountPage />
          </CampaignDonateFormProvider>
        ),
      },

      {
        path: Routes.PAYMENT_SUMMARY(":id"),
        element: (
          <CampaignDonateFormProvider>
            <PaymentSummaryPage />
          </CampaignDonateFormProvider>
        ),
      },
      {
        path: Routes.DONATION_SUCCESSFUL(":id"),
        element: (
          <CampaignDonateFormProvider>
            <DonationCompletedPage />
          </CampaignDonateFormProvider>
        ),
      },
      {
        path: Routes.DONATION_FAILED(":id"),
        element: (
          <CampaignDonateFormProvider>
            <DonationFailedPage />
          </CampaignDonateFormProvider>
        ),
      },
    ],
  },
  {
    path: Routes.SIGNUP,
    element: (
      <Suspense fallback={<KroLoadingIndicator bg="bg-white" />}>
        <OnboardingProvider>
          <OnboardingLayout />
        </OnboardingProvider>
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: Routes.SIGNUP,
        element: <SignUp />,
      },
      {
        path: Routes.ORGANISATION_STEP_ONE,
        element: <OrganizationStepOne />,
      },
      {
        path: Routes.ORGANISATION_STEP_TWO,
        element: <OrganizationStepTwo />,
      },
    ],
  },
  {
    path: Routes.LOGIN,
    element: (
      <Suspense fallback={<KroLoadingIndicator bg="bg-white" />}>
        <ProtectedRoute path={Routes.LOGIN}>
          <OnboardingLayout />
        </ProtectedRoute>
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: Routes.LOGIN,
        element: <LogInPage />,
      },
    ],
  },
  {
    path: Routes.LOGOUT,
    loader: async (_) => {
      useAuthStore.getState().signOut();
      return null;
    },
    element: <Navigate to={Routes.LOGIN} replace />,
  },
]);
