import React from "react";

interface HeaderProps {
  title: string;
  subtitle: string;
}

const Header: React.FC<HeaderProps> = ({ title, subtitle }) => {
  return (
    <div className="mb-[36px] flex w-full flex-col items-start justify-start gap-1">
      <h1 className="font-monrope text-left text-[40px] font-bold leading-[50px] tracking-[-0.005em] text-neutral-900">
        {title}
      </h1>
      <p className="font-inter text-left text-[16px] font-normal leading-[24px] tracking-[-0.011em] text-sub-600">
        {subtitle}
      </p>
    </div>
  );
};

export default Header;
