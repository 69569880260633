// import moment from "moment";
// import { useEffect, useState, FC } from "react";

// interface CustomDatePickerProps {
//   placeholder?: string;
//   style?: string;
//   autoOpen?: boolean;
//   direction?: "above" | "below";
//   onSelect: (date: moment.Moment | null) => void;
//   onClose?: () => void;
// }

// const CustomDatePicker: FC<CustomDatePickerProps> = ({
//   onSelect,
//   autoOpen,
//   style,
//   placeholder,
//   direction = "above",
//   onClose,
// }) => {
//   const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
//   const [tempDate, setTempDate] = useState<moment.Moment>(moment());
//   const [isOpen, setIsOpen] = useState(autoOpen ?? false);

//   useEffect(() => {
//     if (selectedDate) {
//       setTempDate(selectedDate);
//     }
//   }, [selectedDate]);

//   const handleDateChange = (date: moment.Moment) => {
//     setTempDate(date);
//   };

//   const handleSelect = () => {
//     setSelectedDate(tempDate);
//     setIsOpen(false);
//     onSelect(tempDate);
//   };

//   const handleCancel = () => {
//     setTempDate(selectedDate || moment());
//     setIsOpen(false);
//     onClose?.();
//   };

//   const handleClear = () => {
//     setSelectedDate(null);
//     setTempDate(moment());
//     onSelect(null);
//   };

//   const renderCalendar = () => {
//     const startOfMonth = tempDate.clone().startOf("month").startOf("week");
//     const endOfMonth = tempDate.clone().endOf("month").endOf("week");
//     const today = moment();
//     const days = [];
//     let day = startOfMonth;

//     while (day <= endOfMonth) {
//       days.push(day);
//       day = day.clone().add(1, "day");
//     }

//     return (
//       <div className="z-0 w-[368px] scale-100 transform rounded-[20px] border border-neutral-100 bg-white shadow-lg transition-transform duration-300 ease-in-out">
//         <div className="m-5">
//           <div className="mb-2 flex h-9 items-center justify-between rounded-[20px] bg-[#F5F5F5] p-[6px]">
//             <button
//               className={`text-gray-600 ${
//                 tempDate.isSame(today, "month")
//                   ? "cursor-not-allowed opacity-50"
//                   : ""
//               }`}
//               onClick={() =>
//                 !tempDate.isSame(today, "month") &&
//                 handleDateChange(tempDate.clone().subtract(1, "month"))
//               }
//               disabled={tempDate.isSame(today, "month")}
//             >
//               <img src="/assets/icons/calender_arrow_back.svg" alt="Back" />
//             </button>
//             <span className="text-body-md font-normal text-neutral-900">
//               {tempDate.format("MMMM YYYY")}
//             </span>
//             <button
//               className="text-gray-600"
//               onClick={() => handleDateChange(tempDate.clone().add(1, "month"))}
//             >
//               <img src="/assets/icons/calender_arrow_forward.svg" alt="Back" />
//             </button>
//           </div>
//           <div className="grid grid-cols-7 gap-2">
//             {["SU", "MO", "TU", "WE", "TH", "FR", "SA"].map((day) => (
//               <div
//                 key={day}
//                 className="flex h-10 items-center justify-center text-center text-body-md font-medium text-[#99A0AE]"
//               >
//                 {day}
//               </div>
//             ))}
//             {days.map((day) => (
//               <div
//                 key={day.format("DD-MM-YYYY")}
//                 className={`h-10 rounded-[24px] p-2 text-center text-body-md font-medium ${
//                   day.isBefore(today, "day")
//                     ? "cursor-not-allowed text-gray-500"
//                     : day.isSame(tempDate, "day")
//                       ? "bg-brand-dark_green text-white"
//                       : "cursor-pointer hover:bg-neutral-100"
//                 }`}
//                 onClick={() =>
//                   !day.isBefore(today, "day") && handleDateChange(day)
//                 }
//               >
//                 {day.date()}
//               </div>
//             ))}
//           </div>
//         </div>
//         <div className="w-full border-t border-[#E1E4EA]"></div>
//         <div className="flex flex-col-reverse justify-end gap-2 p-4 sm:flex-row">
//           <button
//             className="flex-grow rounded-[33px] border border-[#E5E5E5] px-4 py-2 text-neutral-900 hover:bg-neutral-100"
//             onClick={handleCancel}
//           >
//             Cancel
//           </button>
//           <button
//             className="h-10 flex-grow rounded-[32px] bg-brand-dark_green px-4 py-2 text-body-md font-medium text-white hover:bg-neutral-700"
//             onClick={handleSelect}
//           >
//             Select
//           </button>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div className="custom-date-picker relative z-0">
//       <div
//         onClick={() => setIsOpen(!isOpen)}
//         className="flex cursor-pointer items-center justify-center"
//       >
//         <div className={style ?? ""}>
//           {selectedDate
//             ? `Till ${selectedDate.format("MMMM D, YYYY")}`
//             : (placeholder ?? "Select Date")}
//         </div>
//         {selectedDate && (
//           <button onClick={handleClear} className="ml-1 text-red-500">
//             <img
//               src="/assets/icons/close.svg"
//               alt="delete"
//               width={15}
//               height={15}
//             />
//           </button>
//         )}
//       </div>
//       {isOpen && (
//         <div
//           className={`absolute ${
//             direction === "above" ? "bottom-full mb-2" : "top-full mt-2"
//           } z-10 opacity-100 transition-opacity duration-300 ease-in-out`}
//         >
//           {renderCalendar()}
//         </div>
//       )}
//     </div>
//   );
// };

// export default CustomDatePicker;

import moment from "moment";
import { useEffect, useState, FC } from "react";

interface CustomDatePickerProps {
  placeholder?: string;
  value?: string;
  style?: string;
  autoOpen?: boolean;
  direction?: "above" | "below";
  onSelect: (date: moment.Moment | null) => void;
  onClose?: () => void;
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({
  onSelect,
  autoOpen,
  style,
  placeholder,
  direction = "above",
  value,
  onClose,
}) => {
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [tempDate, setTempDate] = useState<moment.Moment>(moment());
  const [isOpen, setIsOpen] = useState(autoOpen ?? false);

  useEffect(() => {
    if (value && moment(value).isValid()) {
      setSelectedDate(moment(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDate) {
      setTempDate(selectedDate);
    }
  }, [selectedDate]);

  const handleDateChange = (date: moment.Moment) => {
    setTempDate(date);
  };

  const handleSelect = () => {
    setSelectedDate(tempDate);
    setIsOpen(false);
    onSelect(tempDate);
  };

  const handleCancel = () => {
    setTempDate(selectedDate || moment());
    setIsOpen(false);
    onClose?.();
  };

  const handleClear = () => {
    setSelectedDate(null);
    setTempDate(moment());
    onSelect(null);
  };

  const renderCalendar = () => {
    const startOfMonth = tempDate.clone().startOf("month").startOf("week");
    const endOfMonth = tempDate.clone().endOf("month").endOf("week");
    const today = moment();
    const days = [];
    let day = startOfMonth;

    while (day <= endOfMonth) {
      days.push(day);
      day = day.clone().add(1, "day");
    }

    return (
      <div className="z-0 w-[368px] scale-100 transform rounded-[20px] border border-neutral-100 bg-white shadow-lg transition-transform duration-300 ease-in-out">
        <div className="m-5">
          <div className="mb-2 flex h-9 items-center justify-between rounded-[20px] bg-[#F5F5F5] p-[6px]">
            <button
              type="button"
              className={`text-gray-600 ${
                tempDate.isSame(today, "month")
                  ? "cursor-not-allowed opacity-50"
                  : ""
              }`}
              onClick={() =>
                !tempDate.isSame(today, "month") &&
                handleDateChange(tempDate.clone().subtract(1, "month"))
              }
              disabled={tempDate.isSame(today, "month")}
            >
              <img src="/assets/icons/calender_arrow_back.svg" alt="Back" />
            </button>
            <span className="text-body-md font-normal text-neutral-900">
              {tempDate.format("MMMM YYYY")}
            </span>
            <button
              type="button"
              className="text-gray-600"
              onClick={() => handleDateChange(tempDate.clone().add(1, "month"))}
            >
              <img src="/assets/icons/calender_arrow_forward.svg" alt="Back" />
            </button>
          </div>
          <div className="grid grid-cols-7 gap-2">
            {["SU", "MO", "TU", "WE", "TH", "FR", "SA"].map((day) => (
              <div
                key={day}
                className="flex h-10 items-center justify-center text-center text-body-md font-medium text-[#99A0AE]"
              >
                {day}
              </div>
            ))}
            {days.map((day) => (
              <div
                key={day.format("DD-MM-YYYY")}
                className={`h-10 rounded-[24px] p-2 text-center text-body-md font-medium ${
                  day.isBefore(today, "day")
                    ? "cursor-not-allowed text-gray-500"
                    : day.isSame(tempDate, "day")
                      ? "bg-brand-dark_green text-white"
                      : "cursor-pointer hover:bg-neutral-100"
                }`}
                onClick={() =>
                  !day.isBefore(today, "day") && handleDateChange(day)
                }
              >
                {day.date()}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full border-t border-[#E1E4EA]"></div>
        <div className="flex flex-col-reverse justify-end gap-2 p-4 sm:flex-row">
          <button
            type="button"
            className="flex-grow rounded-[33px] border border-[#E5E5E5] px-4 py-2 text-neutral-900 hover:bg-neutral-100"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="h-10 flex-grow rounded-[32px] bg-brand-dark_green px-4 py-2 text-body-md font-medium text-white hover:bg-neutral-700"
            onClick={handleSelect}
          >
            Select
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="custom-date-picker relative z-0">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex cursor-pointer items-center justify-center"
      >
        <div className={style ?? ""}>
          {selectedDate
            ? `Till ${selectedDate.format("MMMM D, YYYY")}`
            : (placeholder ?? "Select Date")}
        </div>
        {selectedDate && (
          <button
            onClick={handleClear}
            className="ml-1 text-red-500"
            type="button"
          >
            <img
              src="/assets/icons/close.svg"
              alt="delete"
              width={15}
              height={15}
            />
          </button>
        )}
      </div>
      {isOpen && (
        <div
          className={`absolute ${
            direction === "above" ? "bottom-full mb-2" : "top-full mt-2"
          } z-10 opacity-100 transition-opacity duration-300 ease-in-out`}
        >
          {renderCalendar()}
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
