import { useForm } from "@tanstack/react-form";
import { useSnackbar } from "common/context/snackbarContext";
import KroBackButton from "features/components/buttons/kroBackButton";
import { KroButton } from "features/components/buttons/kroButton";
import { useCampaignDonateForm } from "../../context/campaignDonateFormProvider";
import Validation from "common/utils/validation";
import KroAmountInputField from "features/components/inputs/KroAmountInput";
import { useState } from "react";
import PresetAmounts from "../campaignDetails/campaignDonatePresetAmount";
import useFetchCampaign from "../../hooks/useFetchCampaign";
import { useNavigate, useParams } from "react-router-dom";
import Routes from "router/routes";
import EmailVerificationModal from "features/onboarding/presentation/components/create_account/EmailVerificationModal";
import { EOTPUseCases } from "features/onboarding/domain/enum/otpUseCase";
import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import KroTextField from "features/components/inputs/kroTextField";
import KroLoader from "features/components/loader/kroLoader";
import { useSendOTP } from "features/onboarding/presentation/hooks/useOTP";

const EnterAmountPage = () => {
  const { formData, setFormData } = useCampaignDonateForm();
  const { showSnackbar } = useSnackbar();

  // const [amount, setAmount] = useState<string>("");

  // const handlePresetSelected = (presetAmount: number) => {
  //   setAmount(presetAmount.toFixed(2));
  // };

  const navigate = useNavigate();

  const { id: campaignId } = useParams<{ id: string }>();
  const { data: campaign } = useFetchCampaign(campaignId || "");
  const mutateOTPRequest = useSendOTP(
    (error: Error) => showSnackbar(error.message),
    () => handleShowVerificationModal(),
  );

  const handleBackButton = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(Routes.CAMPAIGN_DETAILS(campaignId ?? ""));
    }
  };

  const form = useForm({
    defaultValues: {
      amount: formData.amount ?? "",
      firstName: formData.firstName ?? "",
      isEmailVerified: formData.isEmailVerified ?? false,
      lastName: formData.lastName ?? "",
      email: formData.email ?? "",
      reference: formData.reference ?? "",
      paymentMethod: formData.paymentMethod ?? "",
      donateAnonymously: false,
    },

    onSubmitInvalid: (props) => {},

    onSubmit: (values) => {
      //TODO:(ani) put it back
      if (!values.value.isEmailVerified) {
        showSnackbar("Please verify your email address to proceed");
        return;
      }

      console.log(values.value.amount);

      setFormData({
        // amount: values.value.amount,
        firstName: values.value.firstName,
        isEmailVerified: values.value.isEmailVerified,
        lastName: values.value.lastName,
        email: values.value.email,
      });

      navigate(Routes.PAYMENT_SUMMARY(campaignId ?? ""), {
        state: { amount: values.value.amount },
      });

      //   const payload: InitializeDonationPayload = {
      //     amount: total,
      //     firstName: values.value.firstName,
      //     lastName: values.value.lastName,
      //     email: values.value.email,
      //     is_anonymous: values.value.donateAnonymously,
      //     id: campaignModel.id ?? "",
      //     fees: serviceFee,
      //   };

      //   setFormData((prevData) => ({
      //     email: values.value.email,
      //     isEmailVerified: values.value.isEmailVerified,
      //     firstName: values.value.firstName,
      //     lastName: values.value.lastName,
      //   }));

      //   mutation.mutate(payload);
    },
  });
  const isEmailVerified = form.useStore(
    (state) => state.values.isEmailVerified,
  );
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const handleShowVerificationModal = () => {
    setShowVerificationModal(true);
  };

  const handleCloseVerificationModal = (isVerified: boolean) => {
    if (isVerified) form.setFieldValue("isEmailVerified", isVerified);
    setShowVerificationModal(false);
  };
  return (
    <>
      <EmailVerificationModal
        type={EOTPUseCases.SIGN_IN}
        email={form.state.values.email}
        onClose={handleCloseVerificationModal}
        isOpen={showVerificationModal}
      />
      <div className="flex min-h-[calc(100vh-280px)] flex-col">
        <div className="flex flex-grow items-center justify-center px-4">
          <div className="w-full max-w-[608px]">
            <div className="mt-3 flex flex-col items-start rounded-3xl border border-neutral-200 p-6">
              <KroBackButton
                title="Enter amount"
                onclick={handleBackButton}
                classname="lg:flex hidden"
              />
              <p className="mb-4 flex text-left font-inter text-body-lg font-medium leading-[20px] tracking-[-0.006em] lg:hidden">
                Enter amount & details
              </p>
              <form.Field
                name="amount"
                validators={{
                  onChange: ({ value }) => Validation.required(value),
                }}
                children={(field) => {
                  return (
                    <>
                      <KroAmountInputField
                        id={field.name}
                        name={field.name}
                        errorMessage={field.state.meta.errors.join(", ")}
                        title={"Enter Amount"}
                        currency={"₦"}
                        className="mt-7 w-full rounded-[20px] border border-gray-300 bg-[#FAFAFA] px-3"
                        value={field.state.value}
                        placeHolder={"Enter amount"}
                        onChange={(e) => field.handleChange(e)}
                      />
                      {field.state.meta.errors ? (
                        <em role="alert">
                          {field.state.meta.errors.join(", ")}
                        </em>
                      ) : null}
                      {campaign?.targetAmount !== undefined &&
                        (campaign?.targetAmount ?? 0) > 0 && (
                          <PresetAmounts
                            className="mt-0 w-full"
                            targetAmount={campaign?.targetAmount ?? 0}
                            onPresetSelected={(amount) => {
                              form.setFieldValue("amount", amount);
                              field.handleChange(amount.toString());
                            }}
                          />
                        )}
                    </>
                  );
                }}
              />
              <div className="mt-3 flex w-full flex-col gap-1 border-t border-[px] border-stroke-soft-200 pt-6 lg:flex-row lg:justify-between">
                <div className="flex w-full flex-col lg:w-[256px]">
                  <form.Field
                    name="firstName"
                    validators={{
                      onChange: ({ value }) => {
                        const isEmpty = Validation.required(value);
                        if (isEmpty !== undefined) return isEmpty;
                        return Validation.oneWord(value);
                      },
                    }}
                    children={(field) => {
                      return (
                        <KroTextField
                          id={field.name}
                          name={field.name}
                          value={field.state.value}
                          errorMessage={field.state.meta.errors.join(", ")}
                          onChange={(e) => field.handleChange(e.target.value)}
                          labelClassName="text-body-sm text-neutral-700 mb-2"
                          label="First Name"
                          type={KroInputFieldType.TEXT}
                          placeHolder="First Name"
                        />
                      );
                    }}
                  />
                </div>
                <div className="flex w-full flex-col lg:w-[256px]">
                  <form.Field
                    name="lastName"
                    validators={{
                      onChange: ({ value }) => {
                        const isEmpty = Validation.required(value);
                        if (isEmpty !== undefined) return isEmpty;
                        return Validation.oneWord(value);
                      },
                    }}
                    children={(field) => {
                      return (
                        <KroTextField
                          id={field.name}
                          name={field.name}
                          value={field.state.value}
                          errorMessage={field.state.meta.errors.join(", ")}
                          onChange={(e) => field.handleChange(e.target.value)}
                          labelClassName="text-body-sm text-neutral-700 mb-2"
                          label="Last Name"
                          type={KroInputFieldType.TEXT}
                          placeHolder="Last Name"
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <form.Field
                name="email"
                validators={{
                  onChange: ({ value }) => Validation.required(value),
                }}
                children={(field) => {
                  return (
                    <KroTextField
                      margin="mb-0"
                      label={"Email Address"}
                      type={KroInputFieldType.TEXTADDONS}
                      placeHolder={"e.g. john.doe@gmail.com"}
                      id={field.name}
                      className={"mb-10"}
                      name={field.name}
                      fillColor="bg-neutral-50"
                      errorMessage={field.state.meta.errors.join(", ")}
                      value={field.state.value}
                      onChange={(e) => {
                        if (isEmailVerified) {
                          form.setFieldValue("isEmailVerified", false);
                        }
                        return field.handleChange(e.target.value);
                      }}
                      addon={
                        isEmailVerified ? (
                          <img
                            src="/assets/icons/radio_checked.svg"
                            alt="checked"
                          />
                        ) : mutateOTPRequest.isPending ? (
                          <KroLoader />
                        ) : (
                          <KroButton.Base
                            title={"Verify"}
                            type="button"
                            isLoading={mutateOTPRequest.isPending}
                            className="px-3 py-[6px]"
                            hasIcon={false}
                            isDisabled={
                              field.state.meta.errors.length > 0 ||
                              field.name.length < 1
                            }
                            onclick={() =>
                              mutateOTPRequest.mutate({
                                use_case: EOTPUseCases.DONATION,
                                email: field.state.value,
                              })
                            }
                          />
                        )
                      }
                    />
                  );
                }}
              />
              <KroButton
                onclick={() => {
                  form.handleSubmit();
                }}
                title="View summary"
                className="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterAmountPage;
