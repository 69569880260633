import { getSessionUser } from "common/store/useAuthStore";
import STORAGE_KEYS, { removeStorageItem } from "common/utils/localStorage";
import { setStorageItem } from "common/utils/localStorage";
import { getStorageItem } from "common/utils/localStorage";
import { createContext, useContext, useEffect, useState } from "react";

interface CampaignDonateFormContextType {
  formData: Record<string, any>;
  setFormData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  resetFormData: () => void;
}

const CampaignDonateFormContext = createContext<CampaignDonateFormContextType>({
  formData: {},
  setFormData: () => {},
  resetFormData: () => {},
});

export const useCampaignDonateForm = () =>
  useContext(CampaignDonateFormContext);

interface CampaignDonateFormProviderProps {
  children: React.ReactNode;
}

export const CampaignDonateFormProvider = ({
  children,
}: CampaignDonateFormProviderProps) => {
  const user = getSessionUser();
  const [formData, setFormData] = useState<Record<string, any>>(() => {
    const savedData = getStorageItem(STORAGE_KEYS.DONATE_FORM_DATA);

    return savedData && typeof savedData === "string" && savedData !== ""
      ? JSON.parse(savedData)
      : {
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
        };
  });
  useEffect(() => {
    setStorageItem(STORAGE_KEYS.DONATE_FORM_DATA, JSON.stringify(formData));
  }, [formData]);

  const resetFormData = () => {
    removeStorageItem(STORAGE_KEYS.DONATE_FORM_DATA);
  };

  return (
    <CampaignDonateFormContext.Provider
      value={{
        formData,
        resetFormData,
        setFormData,
      }}
    >
      {children}
    </CampaignDonateFormContext.Provider>
  );
};
