import Validation from "common/utils/validation";
import { fundraiserTargetForm } from "../../forms/fudraiserTargetForm";
import { FC } from "react";
interface SupportingDocumentsProps {
  form: fundraiserTargetForm;
}

const MAX_FILE_SIZE = 5; // Size in MB

const SupportingDocuments: FC<SupportingDocumentsProps> = ({ form }) => {
  return (
    <form.Field
      name="supportingDocument"
      validators={{
        onChange: ({ value }) => {
          if (value === null) return undefined;

          return Validation.maxFileSize(value, MAX_FILE_SIZE);
        },
      }}
      children={(field) => {
        const errorMessage = field.state.meta.errors.join(", ");
        return (
          <>
            <p className="mb-3 w-full text-left text-body-md leading-22 tracking-ultralTight">
              Supporting documents
              {errorMessage && (
                <span className="text-left italic text-red-500">
                  {" " + errorMessage}
                </span>
              )}
            </p>
            <div
              className={`flex w-full items-center justify-center rounded-[20px] border border-neutral-200 bg-neutral-50 p-4 ${errorMessage ? "border border-red-500" : ""}`}
            >
              <img
                src="/assets/icons/cloud.svg"
                alt="upload"
                className="mr-3"
              />
              <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-left text-body-md font-medium leading-22 text-neutral-500">
                {field.state.value ? field.state.value.name : "Upload document"}
              </span>
              <input
                id={field.name}
                name={field.name}
                type="file"
                accept=".pdf,.png,.jpg"
                className="hidden"
                onChange={(e) => {
                  field.handleChange(e.target.files?.[0] || null);
                }}
              />
              {field.state.value ? (
                <button
                  type="button"
                  className="ml-auto cursor-pointer rounded-[32px] border border-red-300 px-6 py-1 text-body-md font-medium tracking-ultralTight text-neutral-900 hover:bg-red-100"
                  onClick={() => field.handleChange(null)}
                >
                  Delete
                </button>
              ) : (
                <label
                  htmlFor={field.name}
                  className="ml-auto cursor-pointer rounded-[32px] border border-gray-300 px-6 py-1 text-body-md font-medium tracking-ultralTight text-neutral-900 hover:bg-brand-51"
                >
                  Upload
                </label>
              )}
            </div>
          </>
        );
      }}
    ></form.Field>
  );
};

export default SupportingDocuments;
