import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import DashboardRepository from "../repositories/dashboardRepository";
import CampaignRepository from "features/campaign/domain/repositories/campaignRepository"; // Example import from another feature
import { BankModel } from "../model/bankModel";
import {
  BankAccountModel,
  BankAccountPayload,
} from "../model/bsnkAccounyModel";
import { WithdrawDonationModel } from "../model/withdrawDonationPayload";
interface DashboardService {
  fetchUserCampaigns(): Promise<Array<CampaignModelMapper>>;
  fetchAllCampaigns(): Promise<any>;
  fetchWithdrawalBanks(): Promise<Array<BankModel>>;
  fetchCampaignCategories(): Promise<Array<CampaignModelMapper>>;
  fetchBankAccountInformation(
    payload: BankAccountPayload,
  ): Promise<BankAccountModel>;
  withdrawCampaignDonation(payload: WithdrawDonationModel): Promise<boolean>;
}



export class DashboardServiceImpl implements DashboardService {
  fetchActivityLog(campaignId: string): Promise<any> {
    return DashboardRepository().fetchActivityLog(campaignId);
  }
  fetchUserCampaigns(): Promise<Array<CampaignModelMapper>> {
    return DashboardRepository().fetchUserCampaigns();
  }

  fetchAllCampaigns(): Promise<Array<CampaignModelMapper>> {
    return DashboardRepository().fetchCampaigns();
  }

  fetchCampaignCategories(): Promise<any> {
    return CampaignRepository().fetchCampaignCategories();
  }

    withdrawCampaignDonation(payload: WithdrawDonationModel): Promise<boolean> {
    return DashboardRepository().withdrawCampaignDonation(
    payload
   
    );
  }
  fetchBankAccountInformation(
    payload: BankAccountPayload,
  ): Promise<BankAccountModel> {
    return DashboardRepository().fetchBankAccountInformation(payload);
  }
  fetchWithdrawalBanks(): Promise<Array<BankModel>> {
    return DashboardRepository().fetchWithdrawalBanks();
  }
}
