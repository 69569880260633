import { FC, useState } from "react";

interface KroTooltipProps {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
}

const KroTooltip: FC<KroTooltipProps> = ({ title, subtitle, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const handleTouchStart = () => {
    setIsVisible(true);
  };

  const handleTouchEnd = () => {
    setIsVisible(false);
  };

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div>{children}</div>
      {isVisible && (
        <div
          className={`bg-brand-dark_green box-shadow-[0px_1px_2px_0px_#0E121B08,0px_12px_24px_0px_#0E121B0F] absolute bottom-full left-1/2 z-10 mb-3 -translate-x-1/2 transform rounded-xl p-3 shadow-lg transition-opacity duration-300 ease-in-out ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
          style={{
            maxWidth: "272px",
            whiteSpace: "normal",
            width: "max-content",
          }}
        >
          <div className="flex items-start justify-between">
            <div className="flex w-full items-start">
              {title && (
                <img
                  src="/assets/icons/information_icon.svg"
                  alt="tooltip-icon"
                  className="mr-3 mt-1"
                />
              )}
              <div className="flex w-full flex-col items-start justify-start">
                {title && (
                  <h3 className="break-words text-body-md font-medium leading-[20px] text-white">
                    {title}
                  </h3>
                )}
                {subtitle && (
                  <p
                    className={`${
                      !title
                        ? "text-body-sm text-white"
                        : "text-body-sm text-[#99A0AE]"
                    } break-words text-left leading-[16px]`}
                  >
                    {subtitle}
                  </p>
                )}
              </div>
            </div>
            {title && (
              <button
                onClick={handleMouseLeave}
                className="mt-1 text-gray-500 hover:text-gray-700"
              >
                <img
                  src="/assets/icons/close_tool_tip.svg"
                  className="c"
                  alt="tooltip-icon"
                />
              </button>
            )}
          </div>
          <div className="anchor-arrow"></div>
        </div>
      )}
    </div>
  );
};

export default KroTooltip;
