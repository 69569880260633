

class kroFee{
    percentage: number;
    max: number;
    min:number;
    constructor(data:any) {
        this.percentage = data.perecentage;
        this.max= data.max;
        this.min = data.min
        
    }

    get feePercentWithSymbol(): string {
        return `${this.percentage}%`;
    }

}

export default kroFee;


