import React from "react";

interface KroContainerProps {
  title?: string;
  width?: string;
  border?: string;
  maxWidth?: string;
  padding?: string;
  margin?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
}

const KroContainer = ({
  title,
  width = "w-full",
  padding = "p-6",
  margin = "m-0",
  border = "border border-neutral-300",
  children,
  maxWidth,
  isLoading = false,
}: KroContainerProps) => {
  return (
    <div
      className={`rounded-[24px] text-center leading-28.8 tracking-ultralTight ${border} ${padding} ${width} ${margin} ${
        maxWidth ? `max-w-${maxWidth}` : ""
      }`}
    >
      {title && (
        <h2 className="w-full text-left text-H_8 font-medium text-black">
          {title}
        </h2>
      )}
      {isLoading ? (
        <div className="mt-4 animate-pulse">
          {React.Children.map(children, (child, index) => (
            <div
              key={index}
              className="animate mb-4 rounded bg-brand-dark_green opacity-10"
              style={{ width: "100%", height: "20px" }}
            ></div>
          ))}
        </div>
      ) : (
        <div className="animate-fadeIn">{children}</div>
      )}
    </div>
  );
};

export default KroContainer;
