import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import KroTextField from "features/components/inputs/kroTextField";
import { KroButton } from "features/components/buttons/kroButton";
import KroRadioButtonGroup from "features/components/inputs/KroRadioButtonGroup";
import { useForm } from "@tanstack/react-form";
import UploadDocument from "../components/create_account/uploadDocument";
import Validation from "common/utils/validation";
import Header from "../components/header";
import { useUpdateOrganizationStepTwo } from "../hooks/useOrganizationSetUp";
import { useSnackbar } from "common/context/snackbarContext";
import { useEffect, useState } from "react";
import AccountCreatedModal from "../components/create_account/AccountCreatedModal";
import { useOnboardingForm } from "../context/OnboardingContextProvider";
import Routes from "router/routes";
import { useLocation, useNavigate } from "react-router-dom";

const MAX_FILE_SIZE = 5; // Size in MB

const OrganizationStepTwo = () => {
  const { formData, validateStepAccess } = useOnboardingForm();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    validateStepAccess(location.pathname);
  }, [location, validateStepAccess]);
  const { showSnackbar } = useSnackbar();
  const [showAccountCreatedModal, setShowAccountCreatedModal] = useState(false);
  const handleCloseAccountCreatedModal = () => {
    navigate(Routes.LOGIN);
  };
  const handleShowAccountCreatedModal = () => setShowAccountCreatedModal(true);

  const form = useForm({
    defaultValues: {
      registrationNumber: "",
      isOrganisationRegistered: "",
      supportingDocument: null as File | null,
    },

    onSubmit: ({ value }) => {
      const payload: IOrganizationStepTwoPayload = {
        registration_number: value.registrationNumber,
        file: value.supportingDocument ?? undefined,
        is_organisation_registered: value.isOrganisationRegistered,
      };

      updateOrganizationStepTwoMutation.mutate({ id: formData.id, payload });
    },
  });

  const isOrganisationRegistered = form.useStore(
    (state) => state.values.isOrganisationRegistered,
  );

  const updateOrganizationStepTwoMutation = useUpdateOrganizationStepTwo(
    (error: Error) => {
      showSnackbar(error.message);
    },
    (response: IOrganizationStepOneResponse) => {
      handleShowAccountCreatedModal();
    },
  );

  return (
    <>
      <AccountCreatedModal
        isOpen={showAccountCreatedModal}
        onClose={handleCloseAccountCreatedModal}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          form.handleSubmit();
        }}
        className="flex w-full flex-col items-center justify-center p-3 lg:p-6"
      >
        <Header
          title="Set Up Organisation"
          subtitle="Share more details with us to tailor your experience to your needs."
        ></Header>

        <div className="flex w-full flex-col gap-6">
          <form.Field
            name="isOrganisationRegistered"
            validators={{
              onChange: ({ value }) => {
                return Validation.required(value);
              },
            }}
            children={(field) => (
              <div className="flex w-full flex-col gap-2" id={field.name}>
                <label className="text-sm leading-snug font-['Inter'] font-normal text-neutral-700">
                  Is your Organisation registered?{" "}
                  <span className="italic text-red-500">
                    {field.state.meta.errors.join(", ")}
                  </span>
                </label>
                <div className="flex h-5 items-center">
                  <KroRadioButtonGroup
                    name={field.name}
                    options={[
                      { id: "yes", label: "Yes", value: "true" },
                      { id: "no", label: "No", value: "false" },
                    ]}
                    onChange={(value) => {
                      form.reset();

                      field.handleChange(value);
                    }}
                    selectedValue={field.state.value}
                    direction="row"
                  />
                </div>
              </div>
            )}
          />
          <form.Field
            name="registrationNumber"
            validators={{
              onChange: ({ value }) => {
                if (!value && isOrganisationRegistered !== "true")
                  return undefined;

                return Validation.required(value);
              },
            }}
            children={(field) => (
              <KroTextField
                label={"Registration Number"}
                type={KroInputFieldType.TEXT}
                margin={"mb-0"}
                placeHolder={"RC / CAC Number"}
                id={field.name}
                name={field.name}
                errorMessage={field.state.meta.errors.join(", ")}
                value={field.state.value}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            )}
          />
          <form.Field
            name="supportingDocument"
            validators={{
              onChange: ({ value }) => {
                if (value === null && isOrganisationRegistered !== "true")
                  return undefined;

                if (value === null) return Validation.required(value);

                return Validation.maxFileSize(value, MAX_FILE_SIZE);
              },
            }}
            children={(field) => (
              <UploadDocument
                value={field.state.value}
                error={field.state.meta.errors.join(", ")}
                onChange={(value) => {
                  field.handleChange(value);
                }}
              ></UploadDocument>
            )}
          />
        </div>
        <KroButton
          isLoading={updateOrganizationStepTwoMutation.isPending}
          title={"Continue"}
          type="submit"
          className="mt-8 w-full"
        />
      </form>
    </>
  );
};

export default OrganizationStepTwo;
