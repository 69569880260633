
import { useMutation } from '@tanstack/react-query';
import { CampaignModelMapper } from 'features/campaign/domain/models/campaignModel';
import { UpdateCampaignDurationPayload } from 'features/campaign/domain/models/updateCampaignDurationPayload';
import { UpdateCampaignTargetAmountPayload } from 'features/campaign/domain/models/updateCampaignTargetAmountPayload';
import { CampaignServiceImpl } from 'features/campaign/domain/services/campaignService';
const campaignService = new CampaignServiceImpl();

export const useUpdateCampaignFundraiserDetails = (
  onError: ((error: Error, variables: { fundingPayload: UpdateCampaignTargetAmountPayload; durationPayload: UpdateCampaignDurationPayload; files: File[] }, context: unknown) => Promise<unknown> | unknown) | undefined, 
  onSuccess: ((data: CampaignModelMapper, variables: { fundingPayload: UpdateCampaignTargetAmountPayload; durationPayload: UpdateCampaignDurationPayload; files: File[] }, context: unknown) => Promise<unknown> | unknown) | undefined
) => {
  return useMutation({
    mutationFn: ({ fundingPayload, durationPayload, files }: { fundingPayload: UpdateCampaignTargetAmountPayload; durationPayload: UpdateCampaignDurationPayload; files: Array<File> }) => 
      campaignService.updateFundraiserDetails(files, fundingPayload, durationPayload),
    onError: onError,
    onSuccess: onSuccess,
  });
};