import { useState, useEffect } from "react";

const useKroCountdown = (endDate: Date | string | null | undefined): string => {
  const [timeLeft, setTimeLeft] = useState<string>("");

  useEffect(() => {
    if (!endDate) {
      setTimeLeft("No end date available");
      return;
    }

    const end = new Date(endDate);
    if (isNaN(end.getTime())) {
      setTimeLeft("Invalid date");
      return;
    }

    const updateCountdown = () => {
      const now = new Date();
      const timeDifference = end.getTime() - now.getTime();

      if (timeDifference <= 0) {
        setTimeLeft("Campaign Ended");
        return;
      }

      const monthsLeft = Math.floor(
        timeDifference / (1000 * 60 * 60 * 24 * 30),
      );
      const daysLeft = Math.floor(
        (timeDifference / (1000 * 60 * 60 * 24)) % 30,
      );
      const hoursLeft = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      const minutesLeft = Math.floor((timeDifference / (1000 * 60)) % 60);
      const secondsLeft = Math.floor((timeDifference / 1000) % 60);

      if (monthsLeft > 0) {
        setTimeLeft(`${monthsLeft} month${monthsLeft > 1 ? "s" : ""} left`);
      } else if (daysLeft > 0) {
        setTimeLeft(`${daysLeft} day${daysLeft > 1 ? "s" : ""} left`);
      } else if (hoursLeft > 0) {
        setTimeLeft(`${hoursLeft} hour${hoursLeft > 1 ? "s" : ""} left`);
      } else if (minutesLeft > 0) {
        setTimeLeft(`${minutesLeft} minute${minutesLeft > 1 ? "s" : ""} left`);
      } else if (secondsLeft > 0) {
        setTimeLeft(`${secondsLeft} second${secondsLeft > 1 ? "s" : ""} left`);
      }
    };

    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  return timeLeft;
};

export default useKroCountdown;
