import React, { FC } from "react";

// TODO: Refactor the button to ensure the title text is centered properly, possibly by adjusting padding or flex properties.
interface KroButtonProps {
  title: string;
  onclick?: () => void;
  shadow?: string;
  type?: "button" | "submit" | "reset";
  classes?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  hasIcon?: boolean;
  className?: string;
  animated?: boolean;
}

export const KroButton: FC<KroButtonProps> & { Base: FC<KroButtonProps> } = ({
  title,
  onclick,
  shadow = "shadow-lg",
  type = "button",
  isDisabled = false,
  isLoading = false,
  hasIcon = true,
  className = "",
  animated = true,
}) => {
  return (
    <button
      onClick={onclick}
      type={type}
      disabled={isDisabled || isLoading}
      className={`rounded-full bg-brand-dark_green py-2 ${hasIcon ? "pl-4" : "pl-2"} pr-2 text-white ${shadow} flex transform flex-row items-center justify-between space-x-2 transition duration-300 ease-in-out ${animated ? "hover:scale-105 hover:bg-brand-dark_green" : ""} ${isDisabled || isLoading ? "cursor-not-allowed opacity-50" : ""} ${className}`}
    >
      <p className="flex-grow text-center text-body-md">{title}</p>
      {hasIcon && (
        <div className="relative right-0">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
            {isLoading ? (
              <div className="h-5 w-5 animate-spin rounded-full border-4 border-gray-800 border-t-transparent"></div>
            ) : (
              <img src="/assets/icons/arrow_forward.svg" alt="arrow-back" />
            )}
          </div>
        </div>
      )}
    </button>
  );
};

KroButton.Base = ({
  title,
  onclick,
  shadow = "shadow-lg",
  type = "button",
  isDisabled = false,
  isLoading = false,
  hasIcon = true,
  className = "",
  animated = true,
}) => {
  return (
    <button
      onClick={onclick}
      type={type}
      disabled={isDisabled || isLoading}
      className={`rounded-full bg-brand-dark_green text-white ${shadow} flex transform flex-row items-center justify-between space-x-2 transition duration-300 ease-in-out ${animated ? "hover:scale-105 hover:bg-gray-800" : ""} ${isDisabled || isLoading ? "cursor-not-allowed opacity-50" : ""} ${className}`}
    >
      <p className="flex-grow text-center text-body-md">{title}</p>
      {hasIcon && (
        <div className="relative right-0">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
            {isLoading ? (
              <div className="h-5 w-5 animate-spin rounded-full border-4 border-gray-800 border-t-transparent"></div>
            ) : (
              <img src="/assets/icons/arrow_forward.svg" alt="arrow-back" />
            )}
          </div>
        </div>
      )}
    </button>
  );
};
