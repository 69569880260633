import { formatCurrency } from "common/utils/currencyFormater";
import { generateYoutubeThumbnail } from "common/utils/generateYoutubeThumbnail";
import { getCampaignDefaultImage } from "common/utils/getCampaignDefauiltImage";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { KroButton } from "features/components/buttons/kroButton";
import { FC } from "react";

const CampaignCard: FC<{
  data: CampaignModelMapper;
  buttonTitle?: string;
  onViewClick: () => void;
}> & { Skeleton: FC } = ({ data, onViewClick, buttonTitle }) => {
  const { videoUrl, imageUrls, targetAmount, amountRaised, title, category } =
    data;
  const campaignImage =
    generateYoutubeThumbnail(videoUrl ?? "") ??
    (imageUrls?.[0] || null) ??
    getCampaignDefaultImage(category);

  const progress = Math.min((amountRaised / (targetAmount ?? 0)) * 100, 100);

  return (
    <div className="h-[406px] w-full max-w-[310px] rounded-[32px] border border-[#E4E4E4] p-1 md:p-3">
      <div className="mb-4 h-[200px] w-full overflow-hidden rounded-3xl bg-black">
        {campaignImage && (
          <img
            src={campaignImage}
            alt="campaign preview"
            className="h-full w-full object-cover"
          />
        )}
      </div>
      <div className="flex flex-col justify-between px-2 pb-2">
        <h4 className="line-clamp-2 h-[52px] text-left font-inter text-[18px] font-semibold leading-[26px] tracking-[-0.005em]">
          {title}
        </h4>

        <div className="pt-3">
          <div className={`mb-3 h-[16px] w-full rounded-full bg-[#F3E9D8]`}>
            <div
              className="h-full rounded-full bg-[#4FBA6D]"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <div className="flex items-end">
            <div className="flex flex-1 flex-col gap-[6px]">
              <h5 className="line-clamp-1 text-left font-inter text-[20px] font-medium leading-24 tracking-[-0.005em]">
                {formatCurrency(amountRaised, true)}
              </h5>
              {targetAmount ? (
                <p className="line-clamp-2 text-left font-inter text-[15px] font-normal leading-[22px] tracking-[-0.005em]">
                  raised of {formatCurrency(targetAmount, true)}
                </p>
              ) : (
                <p className="line-clamp-2 text-left font-inter text-[15px] font-normal leading-[22px] tracking-[-0.005em]">
                  raised
                </p>
              )}
            </div>
            <KroButton
              className="h-11"
              title={buttonTitle ?? "view"}
              onclick={onViewClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CampaignCard.Skeleton = () => {
  return (
    <div className="h-[406px] w-full max-w-[310px] animate-pulse rounded-[32px] border border-[#E4E4E4] bg-gray-50 p-1 md:p-3">
      <div className="mb-4 h-[200px] w-full overflow-hidden rounded-3xl bg-gray-100"></div>
      <div className="flex flex-col justify-between px-2 pb-2">
        <div className="mb-2 h-[52px] rounded bg-gray-100"></div>
        <div className="pt-3">
          <div className="mb-4 h-[16px] w-full rounded-full bg-gray-100"></div>
          <div className="flex items-end justify-between gap-2">
            <div className="flex flex-1 flex-col gap-[6px]">
              <span className="h-6 rounded bg-gray-100"></span>
              <span className="h-4 rounded bg-gray-100"></span>
            </div>
            <span className="h-[43px] max-w-[94px] flex-1 rounded-full bg-gray-100"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCard;
