import React from "react";

interface UploadDocumentProps {
  value: File | null;
  onChange: (file: File | null) => void;
  error?: string;
}

const UploadDocument: React.FC<UploadDocumentProps> = ({
  value,
  onChange,
  error,
}) => {
  return (
    <div className="flex w-full flex-col gap-2">
      <label className="text-sm leading-snug font-['Inter'] font-normal text-neutral-700">
        Proof of Registration
      </label>
      <div
        className={`flex w-full items-center justify-center rounded-[20px] border border-neutral-200 bg-neutral-50 p-4 ${error ? "border border-red-500" : ""}`}
      >
        <img src="/assets/icons/cloud.svg" alt="upload" className="mr-3" />
        <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-left text-body-md font-medium leading-22 text-neutral-500">
          {value ? value.name : "Upload document"}
        </span>
        <input
          id="upload-document"
          name="upload-document"
          type="file"
          accept=".pdf,.png,.jpg"
          className="hidden"
          onChange={(e) => {
            onChange(e.target.files?.[0] || null);
          }}
        />
        {value ? (
          <button
            type="button"
            className="ml-auto cursor-pointer rounded-[32px] border border-red-300 px-6 py-1 text-body-md font-medium tracking-ultralTight text-neutral-900 hover:bg-red-100"
            onClick={() => onChange(null)}
          >
            Delete
          </button>
        ) : (
          <label
            htmlFor="upload-document"
            className="ml-auto cursor-pointer rounded-[32px] border border-gray-300 px-6 py-1 text-body-md font-medium tracking-ultralTight text-neutral-900 hover:bg-brand-51"
          >
            Upload
          </label>
        )}
      </div>
    </div>
  );
};

export default UploadDocument;
