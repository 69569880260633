import useDiceBear from "common/hooks/useDiceBear";
import { formatRelativeDate } from "common/utils/dateUtils";
import { CommentsResponse } from "features/campaign/domain/models/comments";

import { FC } from "react";

export const Comments: FC<CommentsResponse> & { Skeleton: FC } = (props) => {
  const { text, createdAt, full_name, isAnonymous } = props;
  const avatar = useDiceBear({
    seed: `${full_name ?? "A"} ${full_name ?? "A"}`,
  });
  const splittedName = full_name.trim().split(" ");
  const firstName = splittedName[0];

  const lastNameInitial =
    splittedName.length > 1 && splittedName[1].trim() !== ""
      ? `${splittedName[1][0].toUpperCase()}.`
      : "";

  return (
    <li className="mt-2 lg:w-[773px]">
      <div className="mt-3 flex flex-col">
        <div className="mt-3 flex flex-row">
          <div className="flex flex-col">
            <img className="rounded-full" src={avatar} alt="Profile" />
          </div>
          <div className="ml-3 flex flex-col">
            <p className="flex items-center">
              {(isAnonymous ?? false)
                ? "Anonymous"
                : `${firstName} ${lastNameInitial ?? ""}`}
              {/* <img
                src="/assets/icons/verified-badge.svg"
                alt="Verified badge"
              /> */}
            </p>
            <div className="flex flex-row">
              {/* <img src="/assets/icons/trophy-line.svg" alt="trophy" /> */}
              <p className="text-body-md font-normal text-sub-600">
                {formatRelativeDate(new Date(createdAt))}
              </p>
            </div>
          </div>
        </div>
        <p className="mt-4 text-justify text-body-sm font-normal text-neutral-900">
          {text}
        </p>
      </div>
    </li>
  );
};

Comments.Skeleton = () => {
  return (
    <li className="mt-2 animate-pulse bg-gray-50 lg:w-[773px]">
      <div className="mt-3 flex flex-col">
        <div className="mt-3 flex flex-row">
          <div className="flex flex-col">
            <div className="mb-2 h-[40px] w-[40px] rounded bg-gray-100"></div>
          </div>
          <div className="ml-3 flex flex-col">
            <p className="flex items-center">
              <div className="w-[94px] bg-red-500"></div>
              <img
                src="/assets/icons/verified-badge.svg"
                alt="Verified badge"
              />
            </p>
            <div className="flex flex-row">
              <img src="/assets/icons/trophy-line.svg" alt="trophy" />
              <p className="ml-2 text-body-md font-normal text-sub-600"></p>
            </div>
          </div>
        </div>
        <p className="mt-4 text-justify text-body-md font-normal text-neutral-900"></p>
      </div>
    </li>
  );
};
