
export interface BankAccountModel {
    id: string;
    accountNumber: string;
    bankName: string;
    accountHolderName: string;
   
}

export interface BankAccountPayload {
    accountNumber: string;
    bankCode: string;
}

export interface BankAccountDetailsResponse {
    account_number: string;
    account_name: string;
    bank_id: number;
}
export function mapToBankAccountModel(response: BankAccountDetailsResponse): BankAccountModel {
    return {
        id: response.bank_id.toString(),
        accountNumber: response.account_number,
        bankName: '', // Not available in the response
        accountHolderName: response.account_name,
    };
}