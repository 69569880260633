import { useRef, useEffect } from "react";

interface KroOTPBoxProps {
  length?: number;
  onChange: (value: string) => void;
  errorMessage?: string;
  isInvalid?: boolean;
  value?: string;
}

const KroOTPBox: React.FC<KroOTPBoxProps> = ({
  length = 6,
  onChange,
  errorMessage,
  isInvalid = false,
  value = "",
}) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (value) {
      value.split("").forEach((char, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index]!.value = char;
        }
      });
    }
  }, [value]);

  const handleChange = () => {
    const value = inputRefs.current.map((input) => input?.value || "").join("");
    onChange(value);
  };

  return (
    <div className="mx-1 flex flex-col items-center">
      <div className="flex gap-2 text-center font-inter text-[32px] font-medium leading-[38.4px] tracking-[-0.04em] sm:gap-[22px]">
        {[...Array(length)].map((_, index) => (
          <input
            key={index}
            type="text"
            maxLength={1}
            className={`h-[46px] w-[53px] rounded-[12px] border bg-[#FAFAFA] p-1 text-center ${isInvalid || errorMessage ? "border-2 border-red-500" : "border-[#E5E5E5]"} focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black`}
            ref={(el) => (inputRefs.current[index] = el)}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                const target = e.target as HTMLInputElement;
                const previousSibling =
                  target.previousElementSibling as HTMLInputElement | null;
                if (target.value === "") {
                  if (previousSibling && previousSibling.tagName === "INPUT") {
                    previousSibling.focus();
                    previousSibling.value = "";
                  }
                } else {
                  target.value = "";
                }
                handleChange();
              }
            }}
            onInput={(e) => {
              const target = e.target as HTMLInputElement;
              const nextSibling =
                target.nextElementSibling as HTMLInputElement | null;
              if (nextSibling && nextSibling.tagName === "INPUT") {
                nextSibling.focus();
              }
              handleChange();
            }}
            onPaste={(e) => {
              const paste = e.clipboardData.getData("text");
              const inputs = Array.from(
                e.currentTarget.parentElement?.querySelectorAll("input") || [],
              );
              inputs.forEach((input, i) => {
                input.value = paste[i] || "";
              });
              if (inputs.length > 0 && inputs[paste.length - 1]) {
                (inputs[paste.length - 1] as HTMLInputElement).focus();
              }
              handleChange();
              e.preventDefault();
            }}
          />
        ))}
      </div>
      {errorMessage && <div className="mt-4 text-red-500">{errorMessage}</div>}
    </div>
  );
};

export default KroOTPBox;
