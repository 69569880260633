import useAuthStore from "common/store/useAuthStore";
import axios from "axios";
import Routes from "router/routes";

const KroClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
  headers: {
    Accept: "application/json",
  },
});

KroClient.interceptors.request.use(
  (config) => {
    const token = useAuthStore.getState().token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// KroClient.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       const authStore = useAuthStore.getState();
//       authStore.signOut();
//       const redirectUrl = encodeURIComponent(
//         window.location.pathname + window.location.search,
//       );
//       window.location.href = `${Routes.LOGIN}?${Routes.REDIRECT_PARAM}=${redirectUrl}`;
//     }
//     return Promise.reject(error);
//   },
// );

KroClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 401) {
        const authStore = useAuthStore.getState();
        authStore.signOut();
        const redirectUrl = encodeURIComponent(
          window.location.pathname + window.location.search,
        );
        window.location.href = `${Routes.LOGIN}?${Routes.REDIRECT_PARAM}=${redirectUrl}`;
        return Promise.reject(new Error("Unauthorized. Please log in again."));
      }

      if (status === 400) {
        throw new Error(data.message || "Bad Request");
      } else if (status === 404) {
        throw new Error("Resource not found.");
      } else if (status === 500) {
        throw new Error("Internal Server Error. Please try again later.");
      } else {
        throw new Error(data.message || "An error occurred. Please try again.");
      }
    } else if (error.request) {
      throw new Error("Network error. Please check your connection.");
    } else {
      throw new Error(error.message || "An unknown error occurred.");
    }
  },
);

export default KroClient;
