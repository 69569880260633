const KroVerifiedBadge: React.FC = () => {
  return (
    <div className="flex items-center justify-center rounded-2xl bg-state-success-lighter px-2 py-[2px] text-body-sm font-medium leading-[16px] text-state-success-base">
      Verified
    </div>
  );
};

const KroCategoryBadge: React.FC<{ category: string }> = ({ category }) => {
  return (
    <div className="rounded-2xl border-[0.8px] border-[#717784] px-2 py-[2px] text-body-sm font-medium leading-[16px] text-[#717784]">
      {category}
    </div>
  );
};

export { KroVerifiedBadge, KroCategoryBadge };
