import { getCampaignDefaultImage } from "common/utils/getCampaignDefauiltImage";
import { useState } from "react";
import ReactPlayer from "react-player";

interface Media {
  type: "image" | "video" | undefined;
  src: string;
}

interface MediaPreviewProps {
  media: Media[];
  campaignCategory: string;
}

const MediaPreview: React.FC<MediaPreviewProps> = ({
  media,
  campaignCategory,
}) => {
  const initialMedia = media.find((item) => item.type) || {
    type: "image",
    src: "",
  };
  const [mainMedia, setMainMedia] = useState(initialMedia);
  const _getCampaignDefaultImage = getCampaignDefaultImage(campaignCategory);

  return (
    <>
      <div className="mb-4 w-full">
        {mainMedia.type === "image" ? (
          mainMedia.src ? (
            <img
              src={mainMedia.src}
              alt="Main Header"
              width={100}
              className="h-[385.43px] w-full rounded-xl bg-black object-contain"
            />
          ) : (
            <img
              src={_getCampaignDefaultImage}
              alt="Main Header"
              width={100}
              className="h-[385.43px] w-full rounded-xl bg-black object-cover"
            />
          )
        ) : mainMedia.type === "video" ? (
          <div className="h-[281.98px] w-full overflow-hidden rounded-xl bg-black md:h-[385.43px]">
            <ReactPlayer
              url={mainMedia.src}
              controls={true}
              playing
              width="100%"
              height="100%"
              className="h-[231.98px] w-full rounded-xl bg-black md:h-[385.43px]"
            />
          </div>
        ) : (
          <div className="flex h-[231.98px] w-full items-center justify-center rounded-xl bg-black">
            <p>No media available</p>
          </div>
        )}
      </div>
      <div className="flex gap-[12.5px] overflow-x-auto">
        {media.map((item, index) => (
          <div
            key={index}
            className={`flex h-[79.57px] w-32 cursor-pointer items-center justify-center rounded-md bg-black ${
              item.src === mainMedia.src ? "border-2 border-neutral-900" : ""
            }`}
            onClick={() => item.type && setMainMedia(item)}
          >
            {item.type === "image" ? (
              <img
                src={item.src || _getCampaignDefaultImage}
                alt={`${index + 1}`}
                className="h-full w-32 rounded-md object-cover"
              />
            ) : item.type === "video" ? (
              <div className="h-full w-32 overflow-hidden rounded-md">
                <ReactPlayer
                  url={item.src}
                  light
                  width="100%"
                  height="100%"
                  playing={false}
                  className="pointer-events-none"
                />
              </div>
            ) : (
              <div className="flex h-full w-32 items-center justify-center rounded-md bg-gray-300">
                <p>No Preview</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default MediaPreview;
