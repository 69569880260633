import { KroButton } from "features/components/buttons/kroButton";
import Routes from "./routes";

//TODO:Change Design / this is a temp design
const ErrorPage = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center text-center">
      <h1 className="text-H_6 text-red-500">Oops!</h1>
      <p className="text-2xl mb-2 text-gray-700">
        We can't seem to find the page you're looking for.
      </p>
      <KroButton
        title="Go Home"
        onclick={() => (window.location.href = Routes.HOME)}
      ></KroButton>
    </div>
  );
};

export default ErrorPage;
