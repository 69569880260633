import React from "react";
import CampaignCard from "./campaignCard";

export enum CampaignerUsersEnum {
  MYSELF = "self",
  SOMEONEELSE = "someone else",
}

export const CampaignTypesComponent = ({
  selectedIndex,
  setSelectedIndex,
}: {
  selectedIndex: number | null;
  setSelectedIndex: (index: number | null) => void;
}) => {
  const campaignTypes = [
    {
      title: "Myself",
      subTitle:
        "You're fundraising for yourself and donations will be sent directly to you.",
      icon: "my_self",
      iconPath: "/assets/icons/my_self.svg",
      onSelected: () => {
        setSelectedIndex(0);
      },
    },
    {
      title: "Someone else",
      icon: "someone_else",
      subTitle:
        "You're fundraising for someone else and the donations will be sent to them.",
      iconPath: "/assets/icons/someone_else.svg",
      onSelected: () => {
        setSelectedIndex(1);
      },
    },
  ];

  return (
    <div>
      {campaignTypes.map((card, index) => (
        <CampaignCard
          key={index}
          title={card.title}
          icon={card.icon}
          subtitle={card.subTitle}
          selected={selectedIndex === index}
          iconPath={card.iconPath}
          onSelected={() => card.onSelected()}
        />
      ))}
    </div>
  );
};
