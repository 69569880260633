import { formatCurrency } from "common/utils/currencyFormater";

interface PresetAmountsProps {
  targetAmount: number;
  onPresetSelected: (amount: number) => void;
  className?: string;
}

const PresetAmounts: React.FC<PresetAmountsProps> = ({
  targetAmount,
  className,
  onPresetSelected,
}) => {
  const baseAmount = targetAmount * 0.25;
  const growthRate = 1.2;
  const presetAmounts = Array.from(
    { length: 4 },
    (_, i) =>
      Math.round((baseAmount * Math.pow(growthRate, i - 1)) / 1000) * 1000,
  );

  return (
    <div className={`mt-4 ${className}`}>
      <div
        className="flex gap-2 overflow-x-auto whitespace-nowrap"
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <style>{`
          .no-scrollbar::-webkit-scrollbar {
            display: none; /* Chrome, Safari, and Opera */
          }
        `}</style>
        {presetAmounts.map((amount, index) => (
          <button
            key={index}
            className="flex w-full max-w-[120px] flex-grow items-center justify-center rounded-[32px] border bg-neutral-50 px-2 py-3 text-center text-body-lg font-medium leading-[24px] hover:bg-neutral-200 sm:max-w-[180px] md:max-w-[200px]"
            onClick={() => onPresetSelected(amount)}
          >
            {`₦${formatCurrency(amount)}`}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PresetAmounts;
