import { PaymentMethodsModel } from "features/campaign/domain/models/paymentMethodsModel";
import { FC } from "react";

interface PaymentMethodsItemProps extends PaymentMethodsModel {
  isSelected: boolean;
  onChange: () => void;
}

const WithdrawalMethodTile: FC<PaymentMethodsItemProps> = ({
  iconPath,
  name,
  isSelected,
  onChange,
}) => {
  return (
    <label
      className={`mt-3 flex max-h-[72px] cursor-pointer items-center justify-between rounded-3xl border p-3 hover:bg-neutral-50 ${
        isSelected ? "border-brand-400" : "border-neutral-200"
      }`}
      onClick={onChange}
    >
      <div className="flex items-center">
        <img src={iconPath} alt={name} className="mr-3" />
        <div className="text-lg font-medium text-neutral-900">{name}</div>
      </div>
      <input
        className="h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 checked:border-neutral-200 checked:bg-primary"
        type="radio"
        name="paymentMethod"
        checked={isSelected}
        onChange={onChange}
      />
    </label>
  );
};

export default WithdrawalMethodTile;
