import { useQuery } from "@tanstack/react-query";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";

const DashboardService = new DashboardServiceImpl();

const useFetchActivityLog = (campaignId: string) => {
  const {
    data: campaigns,
    isLoading,
    error,
    refetch,
  } = useQuery<CampaignModelMapper[], Error>({
    queryKey: ["fetchActivityLog"],
    queryFn: () => DashboardService.fetchActivityLog(campaignId),
  });

  return { campaigns, isLoading, error, refetch };
};

export default useFetchActivityLog;
