import { useEffect, useState } from "react";

const OTPResendCountDown: React.FC<{
  minutes: number;
  onResend: () => Promise<any>;
}> = ({ minutes, onResend }) => {
  const [timeLeft, setTimeLeft] = useState(minutes * 60);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleResend = async () => {
    if (timeLeft <= 0) {
      await onResend();
      setTimeLeft(minutes * 60);
    }
  };

  return (
    <div>
      {timeLeft > 0 && <span className="mx-1">{formatTime(timeLeft)}</span>}
      <button
        type="button"
        disabled={timeLeft > 0}
        className={timeLeft > 0 ? "cursor-not-allowed text-gray-400" : ""}
        onClick={handleResend}
      >
        Resend Code
      </button>
    </div>
  );
};

export default OTPResendCountDown;
