import React from "react";
import { Navigate } from "react-router-dom";
import useAuthStore from "common/store/useAuthStore";

import { ReactElement } from "react";
import Routes from "./routes";

const ProtectedRoute = ({
  path,
  element,
  children,
}: {
  path?: string;
  element?: ReactElement;
  children?: ReactElement;
}) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const location = window.location;

  if (path && (path === Routes.LOGIN || path === Routes.SIGNUP)) {
    if (isAuthenticated) return <Navigate to={Routes.MY_CAMPAIGNS} replace />;
    return <>{element ?? children}</>;
  }

  return (
    <>
      {isAuthenticated ? (
        (element ?? children)
      ) : (
        <Navigate
          to={`${Routes.LOGIN}?${Routes.REDIRECT_PARAM}=${encodeURIComponent(location.pathname)}`}
          replace
        />
      )}
    </>
  );
};

export default ProtectedRoute;
