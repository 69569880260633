import React, { useEffect, useRef } from "react";

interface IframeProps {
  url: string;
  onSuccess: (data: any) => void;
}

const KroIframeMessageListener: React.FC<IframeProps> = ({
  url,
  onSuccess,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const handleIframeMessage = (event: MessageEvent) => {
      // console.log("Message received from iframe:", event.data);
      let data;
      try {
        data =
          typeof event.data === "string" ? JSON.parse(event.data) : event.data;
      } catch (error) {
        // console.error("Failed to parse message data:", error);
        return;
      }

      // console.log("Message received from iframe:", data.reference);

      if (data.status === "success") {
        onSuccess(data);
      }
    };

    window.addEventListener("message", handleIframeMessage);

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  });

  const cacheBustedUrl = `${url}?source=mobile`;

  return (
    <iframe
      title="paystack"
      ref={iframeRef}
      src={cacheBustedUrl}
      style={{ width: "100%", height: "700px" }}
    />
  );
};

export default KroIframeMessageListener;
