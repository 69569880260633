import React from "react";

const KroVideoUploadProgress: React.FC<{
  uploadProgress: number;
  uploadUrl: string | null;
  uploadError: string | null;
  onRetry: () => void;
  onCancel: () => void;
}> = ({ uploadProgress, uploadUrl, uploadError, onRetry, onCancel }) => {
  return (
    <div className="fixed right-5 top-5 z-50 rounded bg-brand-dark_green px-4 py-2 text-white shadow-md">
      {uploadError ? (
        <div className="flex items-center justify-center gap-2">
          <div>Error: {uploadError}</div>
          <button
            onClick={onRetry}
            className="flex items-center justify-center rounded border-none bg-red-500 px-2.5 py-1 text-white"
          >
            Retry
          </button>
        </div>
      ) : (
        <div>
          <div className="font-sans leading-22 tracking-ultralTight text-white">
            Uploading Video: {uploadProgress}%
            <progress
              value={uploadProgress}
              max="100"
              className="mt-2.5 w-full rounded bg-warning-300"
            ></progress>
          </div>
        </div>
      )}
    </div>
  );
};

export default KroVideoUploadProgress;
