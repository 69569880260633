export enum variant {
  gradient,
  solid,
}

const KroProgressBar = (props: KroProgressBarProps) => {
  const amountRaised = parseFloat(props.amountRaised.replace(/,/g, ""));
  const amountToBeRaised = parseFloat(props.amountToBeRasied.replace(/,/g, ""));

  const progressPercentage =
    amountRaised >= amountToBeRaised
      ? 100
      : (amountRaised / amountToBeRaised) * 100;

  switch (props.varient) {
    case variant.gradient:
      return (
        <div
          className={`mb-3 ${props.height ?? "h-4"} rounded-full bg-neutral-200 ${props.customStyle}`}
        >
          <div
            className={`${props.height ?? "h-4"} rounded-full`}
            style={{
              width: `${progressPercentage}%`,
              background: `linear-gradient(to right, #0A9454, #19D67E)`,
            }}
          ></div>
        </div>
      );
    case variant.solid:
      return (
        <div
          className={`h-[8.76px] w-full rounded-full bg-[#F3E9D8] ${props.customStyle}`}
        >
          <div
            className="h-[8.76px] rounded-full"
            style={{
              width: `${progressPercentage}%`,
              background: "#2563EB",
            }}
          ></div>
        </div>
      );
    default:
      return <></>;
  }
};

interface KroProgressBarProps {
  amountRaised: string; // Expecting strings with commas
  amountToBeRasied: string; // Expecting strings with commas
  customStyle?: string;
  varient?: variant;
  height?: string;
}

export default KroProgressBar;
