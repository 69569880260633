import { useMutation } from "@tanstack/react-query";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { CampaignServiceImpl } from "features/campaign/domain/services/campaignService";

const campaignService = new CampaignServiceImpl();

export const useEndCampaign = (
  onError:
    | ((
        error: Error,
        variables: string,
        context: unknown,
      ) => Promise<CampaignModelMapper> | unknown)
    | undefined,
  onSuccess:
    | ((
        data: CampaignModelMapper,
        variables: string,
        context: unknown,
      ) => Promise<CampaignModelMapper> | unknown)
    | undefined,
) => {
  return useMutation({
    mutationFn: (id: string) => campaignService.endCampaign(id),
    onError: onError,
    onSuccess: onSuccess,
  });
};
