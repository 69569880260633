import { useMutation } from '@tanstack/react-query';
import { AuthServiceImpl } from 'features/onboarding/domain/services/authService';

const authService = new AuthServiceImpl();

type UpdateOrganizationStepOneVariables = {
  id: string;
  payload: IOrganizationStepOnePayload;
};


type UpdateOrganizationStepTwoVariables = {
  id: string;
  payload: IOrganizationStepTwoPayload;
};


//TODO:Fix to show user respons message
// export const useUpdateOrganizationStepOne = (
//   onError?: (error: Error, variables: UpdateOrganizationStepOneVariables, context: unknown) => Promise<unknown> | unknown, 
//   onSuccess?: (data: IOrganizationStepOneResponse, variables: UpdateOrganizationStepOneVariables, context: unknown) => Promise<unknown> | unknown
// ) => {
//   return useMutation<IOrganizationStepOneResponse, Error, UpdateOrganizationStepOneVariables>({
//     mutationFn: ({ id, payload }) => authService.updateOrganizationStepOne(id, payload),
//     onError,
//     onSuccess,
//   });
// };


export const useUpdateOrganizationStepOne = (
  onError?: (error: Error, variables: UpdateOrganizationStepOneVariables, context: unknown) => Promise<unknown> | unknown, 
  onSuccess?: (data: IOrganizationStepOneData, variables: UpdateOrganizationStepOneVariables, context: unknown) => Promise<unknown> | unknown
) => {
  return useMutation<IOrganizationStepOneData, Error, UpdateOrganizationStepOneVariables>({
    mutationFn: ({ id, payload }) => authService.updateOrganizationStepOne(id, payload),
    onError,
    onSuccess,
  });
};


export const useUpdateOrganizationStepTwo = (
  onError?: (error: Error, variables: UpdateOrganizationStepTwoVariables, context: unknown) => Promise<unknown> | unknown, 
  onSuccess?: (data: IOrganizationStepOneResponse, variables: UpdateOrganizationStepTwoVariables, context: unknown) => Promise<unknown> | unknown
) => {
  return useMutation<IOrganizationStepOneResponse, Error, UpdateOrganizationStepTwoVariables>({
    mutationFn: ({ id, payload }) => authService.updateOrganizationStepTwo(id, payload),
    onError,
    onSuccess,
  });
};

