import { UseMutationResult } from "@tanstack/react-query";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import { KroButton } from "features/components/buttons/kroButton";
import KroModal from "features/components/modals/kroModal";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Routes from "router/routes";

interface EndCampaignModalProps {
  isOpen: boolean;
  handleClose: () => void;
  endCampaign: UseMutationResult<CampaignModelMapper, Error, string, unknown>;
  campaignId: string;
  isEndCampaignSuccessModalOpen: boolean;
  handleEndCampaignSuccessModalClose: () => void;
}

export const EndCampaignModal: FC<EndCampaignModalProps> = ({
  isOpen,
  handleClose,
  endCampaign,
  campaignId,
  handleEndCampaignSuccessModalClose,
  isEndCampaignSuccessModalOpen,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <KroModal
        closeOnOutsideClick={true}
        lottieAnimation={false}
        isOpen={isOpen}
        onClose={handleClose}
        className=""
        showCloseButton={true}
        maxWidth={442}
        maxHeight={236}
      >
        <div className="mx-[10px] mb-[10px] flex flex-col items-center justify-center">
          <img
            src="/assets/icons/campaign-closed.svg"
            alt=""
            className="mt-6"
          />
          <p className="mt-6 text-body-md font-medium text-strong-950">
            End Campaign?
          </p>
          <p className="mt-3 pl-5 pr-5 text-center text-body-sm text-sub-600">
            Are you sure you want to end this campaign? This action cannot be
            undone .
          </p>
          <div className="mt-6 flex justify-between gap-x-4">
            <button
              onClick={handleClose}
              className={`h-[36px] w-[194px] rounded-2xl border border-neutral-200 bg-neutral-50 pl-4 pr-4 text-body-md text-[#0F0F0F] hover:scale-105 ${endCampaign.isPending ? "cursor-not-allowed opacity-50" : ""}`}
            >
              Discard
            </button>
            <button
              onClick={() => endCampaign.mutate(campaignId)}
              className="flex h-[36px] w-[194px] items-center justify-center rounded-2xl bg-negative-600 text-body-md text-white hover:scale-105"
            >
              {endCampaign.isPending ? (
                <div className="h-5 w-5 animate-spin rounded-full border-4 border-white border-t-transparent"></div>
              ) : (
                "End Campaign"
              )}
            </button>
          </div>
        </div>
      </KroModal>
      <KroModal
        maxWidth={600}
        closeOnOutsideClick={true}
        maxHeight={410}
        isOpen={isEndCampaignSuccessModalOpen}
        onClose={handleEndCampaignSuccessModalClose}
      >
        <div className="flex flex-col items-center justify-center rounded-xl bg-white">
          <img
            src="/assets/icons/campaign-closed.svg"
            alt=""
            className="mt-8"
          />
          <p className="mt-10 text-H_7">Campaign Closed!</p>
          <p className="mt-1 text-body-lg text-neutral-400">
            This Campaign has ended
          </p>
          <KroButton
            title="Back to campaigns"
            className="mb-10 mt-10"
            hasIcon={true}
            onclick={() => navigate(Routes.MY_CAMPAIGNS)}
          />
        </div>
      </KroModal>
    </>
  );
};
