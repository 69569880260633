import { FC, ReactNode } from "react";

const SectionHeader: FC<{ children?: ReactNode; title: string }> = ({
  children,
  title,
}) => {
  return (
    <header className={`mb-9 flex items-center justify-between`}>
      <h3 className="text-left font-inter text-H_7 font-medium leading-[48px] tracking-[-0.05em] sm:text-H_6">
        {title}
      </h3>
      {children}
    </header>
  );
};

export default SectionHeader;
//  <button className="font-inter rounded-[32px] border border-[#E5E5E5] bg-neutral-50 px-6 py-[13.4px] text-body-lg font-medium leading-24 tracking-ultralTight text-[#0F0F0F] hover:bg-neutral-200">
//    See all
//  </button>;
