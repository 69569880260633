import { useMutation } from '@tanstack/react-query';
import { CampaignTypeResponse } from 'features/campaign/domain/models/campaignTypeResponse';
import { CampaignServiceImpl } from 'features/campaign/domain/services/campaignService';

const campaignService = new CampaignServiceImpl();

export const useSetCampaignType = (
  onError: ((error: Error, variables: string, context: unknown) => Promise<unknown> | unknown) | undefined, 
  onSuccess: ((data: CampaignTypeResponse, variables: string, context: unknown) => Promise<CampaignTypeResponse> | unknown) | undefined
) => {
  return useMutation({
    mutationFn: (campaignType: string) => campaignService.setCampaignType(campaignType),
    onError: onError,
    onSuccess: onSuccess,
  });
};