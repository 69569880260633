import { KroButton } from "features/components/buttons/kroButton";
import { useNavigate, useParams } from "react-router-dom";
import Routes from "router/routes";

const DonationFailedPage = () => {
  const navigate = useNavigate();

  const { id: campaignId } = useParams<{ id: string }>();

  const retryPayment = () => {
    navigate(Routes.ENTER_AMOUNT(campaignId ?? ""));
  };

  return (
    <>
      <div className="flex min-h-[calc(100vh-250px)] flex-col">
        <div className="flex flex-grow items-center justify-center px-4">
          <div className="w-full max-w-[608px]">
            <div className="flex flex-col items-center rounded-3xl border border-neutral-200 p-6">
              <img
                src="/assets/icons/donation_failed.svg"
                alt="giving"
                className="mb-1"
              />
              <p className="text-center font-inter text-H_7 tracking-[-0.005em]">
                Donation Failed!
              </p>
              <p className="mb-3 text-body-lg text-neutral-400">
                Your donation was not successful.
              </p>
              <KroButton title="Retry Payment" onclick={retryPayment} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DonationFailedPage;
