import KroProgressBar, {
  variant,
} from "features/components/progress/kroProgressBar";
import React from "react";
import DonorAvatars, { GenerateAvatar } from "./campaignDonorAvatar";

interface FundraiserInfoProps {
  avatarUrl: string;
  fundraiserText: string;
  amountRaised: string;
  goalAmount?: string;
  daysLeft: string;
  numberOfDonors: number;
  isVerified: boolean;
}

const FundraiserInfo: React.FC<FundraiserInfoProps> = ({
  avatarUrl,
  fundraiserText,
  amountRaised,
  goalAmount,
  daysLeft,
  isVerified,
  numberOfDonors,
}) => {
  return (
    <>
      <div className="mb-6 flex items-center">
        <div className="relative flex-shrink-0">
          <GenerateAvatar
            key={""}
            seed={avatarUrl}
            className={`w-[${30}px] h-[${30}px] rounded-full border border-white`}
          />
          {isVerified && (
            <img
              src="/assets/icons/verified-badge.svg"
              alt="Verified Badge"
              className="absolute right-5 top-2 h-[20px] w-[20px] -translate-y-1/2 translate-x-1/2 transform"
            />
          )}
        </div>
        <div className="ml-3 flex justify-center">
          <h4 className="text-left font-inter text-[16px] font-normal leading-[24px] tracking-[-0.011em] text-[#404040] underline underline-offset-[3px]">
            {fundraiserText} started this fundraiser
          </h4>
        </div>
      </div>

      <div className="mb-6 rounded-2xl border-[0.8px] border-neutral-200 px-3 py-5">
        <div className="mb-[10px] flex flex-row items-center justify-between">
          <span className="text-body-lg font-semibold leading-[20px] tracking-[-0.006em] text-neutral-900">
            {goalAmount !== "" && <span>₦{amountRaised}</span>}{" "}
            <span className="font-inter text-body-md font-normal text-neutral-600">
              {goalAmount !== "0" ? "raised of" : "raised"}
            </span>{" "}
            {goalAmount !== "0" && (
              <span className="text-body-md font-normal text-neutral-600">
                ₦{goalAmount}
              </span>
            )}
          </span>
          <span className="text-right font-inter text-body-md font-medium leading-[16px] text-neutral-900">
            {daysLeft}
          </span>
        </div>
        {goalAmount !== "" && (
          <KroProgressBar
            customStyle={"mb-[16px]"}
            amountRaised={amountRaised}
            varient={variant.gradient}
            amountToBeRasied={goalAmount ?? ""}
          />
        )}
        <DonorAvatars count={numberOfDonors} />
      </div>
    </>
  );
};

export default FundraiserInfo;
