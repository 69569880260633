const KroTextBetweenLines = (props: KroTextBetweenLinesProps) => {
  return (
    <div className={`mb-6 flex items-center ${props.className}`}>
      <div className="w-full border-t border-neutral-300"></div>
      <span className="z-10 mx-2 text-[11px] font-medium leading-[12px] text-neutral-400">
        {props.title}
      </span>
      <div className="w-full border-t border-neutral-300"></div>
    </div>
  );
};

interface KroTextBetweenLinesProps {
  title: string;
  className?: string;
}

export default KroTextBetweenLines;
