import { useMutation, } from "@tanstack/react-query";
import { BankAccountModel, BankAccountPayload } from "features/dashboard/domain/model/bsnkAccounyModel";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";

const dashboardService = new DashboardServiceImpl();

const useFetchBankAccountInfo = (
    onError:
        | ((
                error: Error,
                variables: BankAccountPayload,
                context: unknown,
            ) => Promise<unknown> | unknown)
        | undefined,
    onSuccess:
        | ((
                data: BankAccountModel,
                variables: BankAccountPayload,
                context: unknown,
            ) => Promise<unknown> | unknown)
        | undefined,
) => {
    return useMutation({
        mutationFn: (payload: BankAccountPayload) => dashboardService.fetchBankAccountInformation(payload),
        onError: onError,
        onSuccess: onSuccess,
    });
};

export default useFetchBankAccountInfo;
