

const generateYoutubeThumbnail = (youtubeLink:string) : string | null =>{
    
     if (youtubeLink && youtubeLink.includes('youtube.com')) {
      const videoId = youtubeLink.split('v=')[1];
      if (!videoId) return null;
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    }

    if (youtubeLink && youtubeLink.includes('youtu.be')) {
      const videoId = youtubeLink.split('youtu.be/')[1].split('?')[0];
      if (!videoId) return null;
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    }

    return null;

}

export {generateYoutubeThumbnail}