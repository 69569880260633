import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

interface SnackbarProps {
  message: string;
  position?: "top" | "bottom";
  duration?: number;
  action?: SnackbarActionProps | null;
  onClose: () => void;
}

interface SnackbarActionProps {
  title: string;
  onClick: () => void;
}

const Snackbar: React.FC<SnackbarProps> = ({
  message,
  position = "bottom",
  duration = 3000,
  onClose,
  action,
}) => {
  const [visible, setVisible] = useState(false);

  duration = action ? 10000 : duration;

  useEffect(() => {
    setVisible(true);
    const timer = setTimeout(() => {
      setVisible(false);
      setTimeout(onClose, 300);
    }, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return ReactDOM.createPortal(
    <div
      className={`fixed ${position === "top" ? "top-10" : "bottom-20"} left-0 right-0 flex justify-center transition-transform duration-300 ${
        visible
          ? "translate-y-0"
          : position === "top"
            ? "-translate-y-full"
            : "translate-y-full"
      }`}
    >
      <div className="rounded bg-brand-dark_green px-4 py-2 text-white shadow-md">
        {message}
      </div>

      {action && (
        <button
          className="ml-4 rounded bg-white px-4 py-2 text-brand-dark_green"
          onClick={() => {
            action.onClick();
            setVisible(false);
            setTimeout(onClose, 300);
          }}
        >
          {action.title}
        </button>
      )}
    </div>,
    document.body,
  );
};

export default Snackbar;
