import CountryList, { Country } from "country-list-with-dial-code-and-flag";
import { FC, useState } from "react";

const KroPhoneNumberCountryCodeDropDown: FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  const Countries: Country[] = CountryList.getAll().sort((a, b) =>
    a.dialCode.localeCompare(b.dialCode),
  );

  const [selectedCountry, setSelectedCountry] = useState(value);

  const [isOpen, setIsOpen] = useState(false);
  //TODO:increase flag size

  return (
    <div className="relative">
      <button
        type="button"
        className="flex items-center justify-center gap-1"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>
          {CountryList.findOneByCountryCode(selectedCountry)?.flag ??
            Countries[0].flag}
        </span>
        <img
          alt="V"
          src="/assets/icons/drop_down.svg"
          className={`h-3 w-3 text-gray-500 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {isOpen && (
        <ul className="absolute z-10 mt-2 max-h-60 w-28 overflow-auto rounded-md border border-gray-300 bg-white shadow-lg">
          {Countries.map((country) => (
            <li
              key={country.code}
              className="flex cursor-pointer items-center gap-2 p-2 hover:bg-gray-100"
              onClick={() => {
                setSelectedCountry(country.code);
                onChange(country.code);
                setIsOpen(false);
              }}
            >
              <span>{country.flag}</span>
              <span>{country.dialCode}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default KroPhoneNumberCountryCodeDropDown;
