import { useQuery } from "@tanstack/react-query";
import { AuthServiceImpl } from "features/onboarding/domain/services/authService";

const authService = new AuthServiceImpl();

export const useCategories = () => {
  const {
    data,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["organizationCategories"],
    queryFn: () => authService.getAllCategories(),
  });
  const categories = data?.data ?? [];

  return {
    categories,
    isLoading,
    error,
    isError,
  };
};
