import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { KroButton } from "features/components/buttons/kroButton";
import { useSetCampaignType } from "../hooks/useCreateCampaign";
import { CampaignTypeResponse } from "features/campaign/domain/models/campaignTypeResponse";
import {
  CampaignerUsersEnum,
  CampaignTypesComponent,
} from "../components/campaignCreate/campaignTypesComponent";
import { useCreateCampaignForm } from "../context/CreateCampaignFormProvider";
import Routes from "router/routes";
import { useSnackbar } from "common/context/snackbarContext";
import CampaignCounterComponent from "../components/campaignCreate/campaignPageCounterComponent";

const CampaignTypesPage = () => {
  const { formData, setFormData } = useCreateCampaignForm();

  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    formData.campaignType ?? null,
  );

  const mutation = useSetCampaignType(
    (error: Error) => {
      showSnackbar(error.message);
    },
    (data: CampaignTypeResponse) => {
      setFormData((prevData: any) => ({
        id: data.id,
        campaignType: selectedIndex ?? 0,
      }));
      navigate(Routes.CREATE_CAMPAIGN_INFORMATION);
    },
  );

  const setCampaignBeneficiary = () => {
    const beneficiary =
      selectedIndex === 0
        ? CampaignerUsersEnum.MYSELF
        : selectedIndex === 1
          ? CampaignerUsersEnum.SOMEONEELSE
          : null;

    if (!beneficiary) {
      return;
    }
    mutation.mutate(beneficiary);
  };

  return (
    <div className="mb-4 h-full w-full max-w-[606px] animate-fadeIn md:my-auto">
      <div className="flex h-full flex-col justify-between">
        <div className="md:my-auto">
          <CampaignCounterComponent
            pageCount={1}
            title={"I'm raising money for..."}
            showSkip={false}
          ></CampaignCounterComponent>

          <CampaignTypesComponent
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        </div>
        <div className="flex h-auto w-full flex-col-reverse justify-end gap-3 py-[51px] md:flex-row">
          <KroButton
            isDisabled={selectedIndex == null}
            onclick={setCampaignBeneficiary}
            isLoading={mutation.isPending}
            title={"Get Started"}
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignTypesPage;
