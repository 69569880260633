export enum KroInputFieldType {
  TEXT,
  TEXTAREA,
  TEXTWITHADDONS,
  COPIER,
  TEXTADDONS,
}

export enum kroInputType {
  NUMBER,
}
