interface DonationResponse {
  reference: string;
  transaction_status: string;
  email: string;
  amount: number;
  payment_link: string;
}

export interface DonationResponseMapper {
  tranactionStatus: string;
  reference: string;
  email: string;
  amount: number;
  paymentLink?: string;
}

export interface DonationResponseMapperWithAnon extends DonationResponseMapper {
  isAnonymous :boolean
  firstName:string, 
  lastName:string, 
}


export function mapDonationResponse(
  data: DonationResponse,
): DonationResponseMapper {
  return {
    tranactionStatus: data.transaction_status,
    reference: data.reference,
    email: data.email,
    amount: data.amount,
    paymentLink: data.payment_link,
  };
}
