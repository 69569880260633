const KroBackButton = (props: KroBackButtonProps) => {
  return (
    <button
      onClick={props.onclick}
      className={`flex cursor-pointer items-center justify-center ${props.classname}`}
    >
      <img
        src={props.icon ?? "/assets/icons/arrow-back.svg"}
        alt="arrow-left"
        className="mr-2"
      />
      <span className="text-body-md font-medium text-[#0F0F0F]">
        {props.title ?? "Back"}
      </span>
    </button>
  );
};

interface KroBackButtonProps {
  onclick: () => void;
  icon?: string;
  title?: string;
  classname?: string;
}

export default KroBackButton;
