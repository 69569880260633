import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals.js";
import { SplitFactoryProvider } from "@splitsoftware/splitio-react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { kroRouter } from "router/router";
import { SnackbarProvider, useSnackbar } from "common/context/snackbarContext";
import useAuthStore from "common/store/useAuthStore";
import KroConstants from "common/constants/kroConstants";

export const queryClient = new QueryClient();

const AuthListener = () => {
  const { showSnackbar } = useSnackbar();

  useAuthStore.subscribe((state, prevState) => {
    if (!state.isAuthenticated && prevState.isAuthenticated) {
      showSnackbar(KroConstants.AUTH_ERROR_MSG);
    }
  });
  return <></>;
};

const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: "h4tmd612erfe672sfdfp412aidhmlnjcra8b",
    // key represents your internal user id, or the account id that
    // the user belongs to.
    // This could also be a cookie you generate for anonymous users.
    key: "key",
  },
};

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <SplitFactoryProvider config={sdkConfig}>
      <SnackbarProvider>
        <AuthListener />
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={kroRouter} />
        </QueryClientProvider>
      </SnackbarProvider>
    </SplitFactoryProvider>
  </StrictMode>,
);

reportWebVitals();
