import KroModal from "features/components/modals/kroModal";
import React from "react";
import CampaignDetailsShareComponent from "./campaignDetailsShareComponent";

interface KroShareModalProps {
  campaignId: string;
  onClose: () => void;
  isOpen: boolean;
}

const KroShareModal: React.FC<KroShareModalProps> = ({
  isOpen,
  campaignId,
  onClose,
}) => {
  return (
    <>
      <KroModal
        closeOnOutsideClick={true}
        lottieAnimation={false}
        isOpen={isOpen}
        onClose={onClose}
        className="px-3 py-3"
        showCloseButton={true}
        maxWidth={608}
        maxHeight={466}
      >
        <CampaignDetailsShareComponent
          url={window.location.origin + window.location.pathname}
          campaignId={campaignId ?? ""}
        />
      </KroModal>
    </>
  );
};

export default KroShareModal;
