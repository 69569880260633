import { UserModel } from 'common/models/userModel';
import  STORAGE_KEYS, { clearAllStorageItems, clearStorageItems } from 'common/utils/localStorage';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AuthState {
  isAuthenticated: boolean;
  token: string | null;
  signIn: (token: string, user:UserModel | null) => void;
  signOut: () => void;
  user: UserModel | null;
}

const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      isAuthenticated: false,
      token: null,
      signIn: (token: string, user: UserModel | null) => {
        clearStorageItems([STORAGE_KEYS.DONATE_FORM_DATA])
        return set({ isAuthenticated: true, token, user });
      },
      signOut: () => {
        clearAllStorageItems()
        return set({ isAuthenticated: false, token: null, user:null});
      },
      user: null,
    }),
    {
      name: 'auth-storage', 
    }
  )
);

export default useAuthStore;

const getSessionUser = () => {
  const { user } = useAuthStore.getState();
  return user;
};

export { getSessionUser };