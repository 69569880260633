import { useQuery } from "@tanstack/react-query";
import { CampaignCategoryModel } from "features/campaign/domain/models/campaignCategoryModel";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";

const DashboardService = new DashboardServiceImpl();

export const useFetchCampaignCategories = () => {
  const {
    data: categories,
    isLoading,
    refetch,
    isError,
    error,
  } = useQuery<Array<CampaignCategoryModel>>({
    queryKey: ["campaignCategories"],
    queryFn: () => DashboardService.fetchCampaignCategories(),
  });

  return {
    categories,
    isLoading,
    error,
    refetch,
    isError,
  };
};
