import KroClient from "common/io/client/kroClient";
import { KroEndpoints } from "common/io/endpoints";
import {
  CampaignModelMapper,
  mapCampaignModel,
} from "features/campaign/domain/models/campaignModel";
import { WithdrawalBanksResponse } from "../model/bankModel";
import {
  BankAccountDetailsResponse,
  BankAccountModel,
  BankAccountPayload,
  mapToBankAccountModel,
} from "../model/bsnkAccounyModel";
import { WithdrawDonationPayload } from "../model/withdrawDonationPayload";

const DashboardRepository = () => {
  const fetchUserCampaigns = async (): Promise<Array<CampaignModelMapper>> => {
    try {
      const response = await KroClient.get(KroEndpoints.fetchUserCampaigns);
      return response.data.map((campaign: any) => mapCampaignModel(campaign));
    } catch (error) {
      throw error;
    }
  };

  const fetchCampaigns = async (): Promise<Array<CampaignModelMapper>> => {
    try {
      const response = await KroClient.get(KroEndpoints.fetchCampaigns);
      return response.data.map((campaign: any) => mapCampaignModel(campaign));
    } catch (error) {
      throw error;
    }
  };
  const fetchWithdrawalBanks = async (): Promise<
    Array<WithdrawalBanksResponse>
  > => {
    const response = await KroClient.get(KroEndpoints.withdrawalFetchBanks);
    return response.data;
  };

  const fetchBankAccountInformation = async (
    payload: BankAccountPayload,
  ): Promise<BankAccountModel> => {
    const response = await KroClient.post(
      KroEndpoints.fetchBankAccountInformation,
      {
        account_number: payload.accountNumber,
        bank_code: payload.bankCode,
      },
    );
    return mapToBankAccountModel(response.data as BankAccountDetailsResponse);
  };

  const withdrawCampaignDonation = async (
    payload: WithdrawDonationPayload,
  ): Promise<boolean> => {
    const response = await KroClient.post(
      KroEndpoints.withdrawCampaignDonations,
      payload,
    );
    return response.data;
  }

  const fetchActivityLog = async (campaignId: string): Promise<[]> => {
    try {
      const response = await KroClient.get(
        KroEndpoints.fetchActivityLog(campaignId),
      );
      console.log(response.data, "activity log");
      return response.data.map((campaign: any) => mapCampaignModel(campaign));
    } catch (error) {
      throw error;
    }
  };

  return {
    fetchUserCampaigns,
    withdrawCampaignDonation,
    fetchCampaigns,
    fetchBankAccountInformation,
    fetchWithdrawalBanks,
    
    fetchActivityLog,
  };
};

export default DashboardRepository;
