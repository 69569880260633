import React from "react";

interface KroLoaderProps {
  height?: string;
  width?: string;
}

const KroLoader: React.FC<KroLoaderProps> = ({
  height = "h-5",
  width = "w-5",
}) => {
  return (
    <div
      className={`${height} ${width} animate-spin rounded-full border-4 border-gray-800 border-t-transparent`}
    ></div>
  );
};

export default KroLoader;
