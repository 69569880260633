import Snackbar from "features/components/snackbar/kroSnackBar";
import React, { createContext, useState, useContext, ReactNode } from "react";

interface SnackbarContextType {
  showSnackbar: (
    message: string,
    position?: "top" | "bottom",
    duration?: number,
  ) => void;
  showActionSnackbar: (
    message: string,
    buttonTitle: string,
    onClick: () => void,
    position?: "top" | "bottom",
    duration?: number,
  ) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined,
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children,
}) => {
  const [snackbar, setSnackbar] = useState<{
    message: string;
    position: "top" | "bottom";
    duration: number;
    buttonTitle?: string;
    onClick?: () => void;
  } | null>(null);

  const showSnackbar = (
    message: string,
    position: "top" | "bottom" = "bottom",
    duration: number = 3000,
  ) => {
    setSnackbar({ message, position, duration });
  };
  const showActionSnackbar = (
    message: string,
    buttonTitle: string,
    onClick: () => void,
    position: "top" | "bottom" = "bottom",
    duration: number = 3000,
  ) => {
    setSnackbar({ message, position, duration, buttonTitle, onClick });
  };

  const handleClose = () => {
    setSnackbar(null);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, showActionSnackbar }}>
      {children}
      {snackbar && (
        <Snackbar
          action={
            snackbar.buttonTitle && snackbar.onClick
              ? { title: snackbar.buttonTitle, onClick: snackbar.onClick }
              : null
          }
          message={snackbar.message}
          position={snackbar.position}
          duration={snackbar.duration}
          onClose={handleClose}
        />
      )}
    </SnackbarContext.Provider>
  );
};
