import { TabController } from "features/components/tab/kroTabBar";
import { useEffect } from "react";



 const useFormHandler = (form:any, tabController:TabController) => {
    useEffect(() => {
      if (tabController) {
        tabController.onSwitchTab = (_) => {
          if (!form.state.isDirty) {
            form.reset();
            return true;
          }

          const exit = window.confirm(
            "You have unsaved changes. Do you want to leave without saving?",
          );

          if (exit) {
            form.reset();
            return true;
          }
          return false;
        };
      }
    }, [form, tabController]);
  };

  export default useFormHandler;