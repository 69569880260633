import { useState } from "react";

//TODO:fix show more button not working
const KroText: React.FC<{
  text: string;
  minWords: number;
}> = ({ text, minWords }) => {
  const [showMore, setShowMore] = useState(false);

  const splitText = (text: string) => {
    const regex = /(<[^>]*>|[^<\s]+)(\s*)/g;
    const chunks = [];
    let match;
    let currentWordCount = 0;
    let currentText = "";

    while ((match = regex.exec(text)) !== null) {
      const word = match[0];
      currentText += word;

      if (!word.startsWith("<")) {
        currentWordCount++;
      }

      if (currentWordCount >= minWords) {
        chunks.push(currentText.trim());
        currentText = "";
        currentWordCount = 0;
      }
    }

    if (currentText.trim()) {
      chunks.push(currentText.trim());
    }

    return chunks;
  };

  const words = splitText(text);
  const shouldShowMore = words.length > minWords;

  return (
    <p className="break-words text-left font-inter text-[12.74px] font-normal leading-[19.11px] tracking-[-0.011em] text-neutral-500">
      {showMore || !shouldShowMore ? (
        <span dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        <>
          {words.slice(0, minWords).join(" ")}
          {"..."}
        </>
      )}
      {shouldShowMore && (
        <button
          className="mt-2 text-[12.74px] font-medium leading-[10px] tracking-[-0.011em] text-[#2563EB]"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "" : " See all"}
        </button>
      )}
    </p>
  );
};

export default KroText;
