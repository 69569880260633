import { useMutation } from "@tanstack/react-query";
import { UpdateCampaignStatusPayload } from "features/campaign/domain/models/updateCampaignStatusPayload";
import { CampaignServiceImpl } from "features/campaign/domain/services/campaignService";

const campaignService = new CampaignServiceImpl();

export const useUpdateCampaignStatus = (
  onError: ((error: Error) => Promise<unknown> | unknown) | undefined,
  onSuccess: ((data: boolean) => Promise<unknown> | unknown) | undefined,
) => {
  return useMutation({
    mutationFn: (payload: UpdateCampaignStatusPayload) =>
      campaignService.updateCampaignStatus(payload),

    onError: onError,
    onSuccess: onSuccess,
  });
};
