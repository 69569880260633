import { useQuery } from "@tanstack/react-query";
import { BankModel } from "features/dashboard/domain/model/bankModel";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";

const DashboardService = new DashboardServiceImpl();

const useFetchBanks = () => {
  const { data:banks, isLoading, error, refetch } = useQuery<BankModel[], Error>({
    queryKey: ["FetchBanks"],
    queryFn: () => DashboardService.fetchWithdrawalBanks(),
  });

  return { banks, isLoading, error, refetch };
};

export default useFetchBanks;
