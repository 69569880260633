import KroConstants from "common/constants/kroConstants";
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import Routes from "router/routes";

interface OrganisationSetup {
  id: string;
  name: string;
  email: string;
  category: string;
  country: string;
  state: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneNumberCountry: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
}

interface OnboardingContextType {
  formData: OrganisationSetup;
  setFormData: React.Dispatch<React.SetStateAction<OrganisationSetup>>;
  validateStepAccess: (path: string) => void;
  resetFormData: () => void;
}

const ONBOARDING_FORM_DATA = "ONBOARDING_FORM_DATA";
const DEFAULT_COUNTRY_CODE = KroConstants.DEFAULT_COUNTRY;

const initialFormData: OrganisationSetup = {
  id: "",
  name: "",
  phoneNumberCountry: DEFAULT_COUNTRY_CODE,
  email: "",
  category: "",
  country: "",
  state: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  isEmailVerified: false,
  isPhoneVerified: false,
};

const OnboardingContext = createContext<OnboardingContextType>({
  formData: initialFormData,
  setFormData: () => {},
  validateStepAccess: () => {},
  resetFormData: () => {},
});

export const useOnboardingForm = () => useContext(OnboardingContext);

interface OnboardingProviderProps {
  children: ReactNode;
}

export const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const [formData, setFormData] = useState<OrganisationSetup>(() => {
    const savedData = localStorage.getItem(ONBOARDING_FORM_DATA);
    return savedData ? JSON.parse(savedData) : initialFormData;
  });
  const navigate = useNavigate();

  const validateStepAccess = (currentStep: string) => {
    const sanitizedStep = currentStep.endsWith("/")
      ? currentStep.slice(0, -1)
      : currentStep;

    if (!formData && sanitizedStep !== Routes.SIGNUP) {
      navigate(Routes.SIGNUP);
      return;
    }
    if (
      sanitizedStep === Routes.ORGANISATION_STEP_ONE &&
      (!formData.id || !formData.isEmailVerified)
    ) {
      navigate(Routes.SIGNUP);
      return;
    }

    if (
      sanitizedStep === Routes.ORGANISATION_STEP_TWO &&
      !formData.isPhoneVerified
    ) {
      navigate(Routes.ORGANISATION_STEP_ONE);
      return;
    }
  };

  const resetFormData = () => {
    localStorage.removeItem(ONBOARDING_FORM_DATA);
    setFormData(initialFormData);
  };

  useEffect(() => {
    localStorage.setItem(ONBOARDING_FORM_DATA, JSON.stringify(formData));
  }, [formData]);

  return (
    <OnboardingContext.Provider
      value={{ formData, setFormData, validateStepAccess, resetFormData }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
