import { useSnackbar } from "common/context/snackbarContext";
import { CampaignModelMapper } from "features/campaign/domain/models/campaignModel";
import useFetchCampaign from "features/campaign/presentation/hooks/useFetchCampaign";
import KroLoadingIndicator, {
  LoaderType,
} from "features/components/loader/kroCircularIndicator";
import TabBar, { createTabController } from "features/components/tab/kroTabBar";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FundingDetailsTab from "../components/editCampaign/fundraiserDetailsTab";
import PhotoVideosTab from "../components/editCampaign/photoVideoTab";
import FundingTargetTab from "../components/editCampaign/fundraiserTargetTab";
import Routes from "router/routes";

export enum EditCampaignTabs {
  details = "details",
  photo = "photo",
  target = "target",
}

const EditCampaignPage: FC = () => {
  const { id: campaignId } = useParams<{ id: string }>();
  // const location = useLocation();
  const { showActionSnackbar } = useSnackbar();
  const tabController = createTabController();
  const navigate = useNavigate();

  const {
    data: campaignData,
    isLoading,
    error,
    refetch,
  } = useFetchCampaign(campaignId || "");
  const campaign = campaignData as CampaignModelMapper | undefined;

  console.log(campaign);

  const handleBackButton = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(Routes.MY_CAMPAIGNS);
    }
  };

  //TODO:proper error handling
  useEffect(() => {
    if (error) {
      showActionSnackbar(error.message, "reload", refetch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  if (isLoading) {
    return <KroLoadingIndicator type={LoaderType.FIXED} />;
  }

  return (
    <div className="mx-auto mb-12 max-w-[1290px] p-6 lg:p-0">
      <button
        type="button"
        onClick={handleBackButton}
        className="decoration-skip-ink-none flex items-center gap-3 pt-5 text-left font-inter text-[18px] font-medium leading-[24px] tracking-[-0.015em] text-warning-700 hover:underline lg:mb-[16px]"
      >
        <img src="/assets/icons/arrow-left-line.svg" alt="back" /> Back to
        Campaign
      </button>

      <h2 className="decoration-skip-ink-none mb-[36px] text-left font-inter text-[20px] font-medium leading-[38.4px] tracking-[-0.005em] text-neutral-900 lg:text-[32px]">
        Edit Fundraiser
      </h2>

      <TabBar
        tabController={tabController}
        tabs={[
          {
            name: EditCampaignTabs.details,
            label: "Fundraiser",
            component: !campaign ? (
              <KroLoadingIndicator type={LoaderType.FIXED} />
            ) : (
              <FundingDetailsTab
                campaign={campaign}
                tabController={tabController}
              />
            ),
          },
          {
            name: EditCampaignTabs.photo,
            label: "Photo & Video",
            component: !campaign ? (
              <KroLoadingIndicator type={LoaderType.FIXED} />
            ) : (
              <PhotoVideosTab
                campaign={campaign}
                tabController={tabController}
              />
            ),
          },
          {
            name: EditCampaignTabs.target,
            label: "Funding Target",
            component: !campaign ? (
              <KroLoadingIndicator type={LoaderType.FIXED} />
            ) : (
              <FundingTargetTab
                campaign={campaign}
                tabController={tabController}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default EditCampaignPage;
