import { useMutation } from "@tanstack/react-query";
import { DonationResponseMapper } from "features/campaign/domain/models/donationResponse";
import { VerifyDonationPayload } from "features/campaign/domain/models/verifyDonationPayload";
import { CampaignServiceImpl } from "features/campaign/domain/services/campaignService";

const campaignService = new CampaignServiceImpl();

const useVerifyDonation = (
  onError:
    | ((
        error: Error,
        variables: VerifyDonationPayload,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined,
  onSuccess:
    | ((
        data: DonationResponseMapper,
        variables: VerifyDonationPayload,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined,
) => {
  return useMutation({
    mutationFn: (payload: VerifyDonationPayload) =>
      campaignService.verifyDonation(payload),
    onError: onError,
    onSuccess: onSuccess,
  });
};

export default useVerifyDonation;
