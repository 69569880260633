import { useMutation } from '@tanstack/react-query';
import { UpdateCampaignInformationPayload, UpdateCampaignInformationPayloadWithPrivacy } from 'features/campaign/domain/models/updateCampaignInformationPayload';
import { CampaignServiceImpl } from 'features/campaign/domain/services/campaignService';

// const campaignService = new CampaignServiceImpl();

// export const useUpdateCampaignInformation = (payload: UpdateCampaignInformationPayload) => {
//   return useMutation({
//     mutationFn: () => campaignService.updateCampaignInformation(payload)
//   });
// };



const campaignService = new CampaignServiceImpl();
export const useUpdateCampaignInformation = (
  onError: ((error: Error, variables: UpdateCampaignInformationPayload, context: unknown) => Promise<unknown> | unknown) | undefined, 
  onSuccess: ((data: boolean, variables: UpdateCampaignInformationPayloadWithPrivacy, context: unknown) => Promise<unknown> | unknown) | undefined
) => {
  return useMutation({
    mutationFn: (payload: UpdateCampaignInformationPayloadWithPrivacy) => campaignService.updateCampaignInformation(payload),
    onError: onError,
    onSuccess: onSuccess,
  });
};
