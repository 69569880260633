import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

interface Tab {
  name: string;
  label: string;
  badge?: number;
  component: React.ReactNode;
}

interface TabBarProps {
  tabs: Tab[];
  tabController?: TabController;
}

const TabBar: React.FC<TabBarProps> = ({ tabs, tabController: controller }) => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialTab = Object.keys(query)[0] || tabs[0].name;
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    const newUrl = `${location.pathname}?${activeTab}`;
    window.history.replaceState({}, "", newUrl);
  }, [activeTab, location.pathname]);
  const handleTabClick = useCallback(
    (tabName: string) => {
      if (controller?.onSwitchTab) {
        const canSwitch = controller.onSwitchTab(tabName);
        if (canSwitch) {
          setActiveTab(tabName);
        }
      } else {
        setActiveTab(tabName);
      }
    },
    [controller],
  );

  const activeTabComponent = tabs.find(
    (tab) => tab.name === activeTab,
  )?.component;

  return (
    <div>
      <div className="flex gap-6 border-b border-[#E1E4EA]">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            onClick={() => handleTabClick(tab.name)}
            className={`flex items-center gap-1 ${
              activeTab === tab.name
                ? "border-b-2 border-neutral-900 text-strong-950"
                : "text-[#525866]"
            }`}
          >
            <span className="py-[14px] text-center text-body-md font-medium leading-[20px] tracking-[-0.006em]">
              {tab.label}
            </span>
            {tab.badge !== undefined && (
              <span className="text-center font-inter text-[11px] font-medium leading-[12px] tracking-[0.02em] text-[#525866]">
                {tab.badge}
              </span>
            )}
          </button>
        ))}
      </div>
      <div className="mt-4">{activeTabComponent}</div>
    </div>
  );
};

export default TabBar;

export interface TabController {
  onSwitchTab?: (nextTab: string) => boolean;
}

export const createTabController = (): TabController => {
  const controller: TabController = {
    onSwitchTab: (nextTab) => true,
  };

  return controller;
};
