import useCloudinaryVideoUpload, {
  UseCloudinaryVideoUpload,
} from "common/hooks/useCloudinaryVideoUpload";
import KroVideoUploadProgress from "features/components/display/kroVideoUploadProgress";
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
  useEffect,
} from "react";
import { useCreateCampaignForm } from "./CreateCampaignFormProvider";
import { useSaveVideoLink } from "../hooks/useUploadFilesAndSaveVideoLink";

interface UploadVideoContextProps
  extends Omit<UseCloudinaryVideoUpload, "uploadVideo" | "cancelUpload"> {
  onUploadVideo: (file: File) => void;
  isVideoUploading: boolean;
}

const VideoUploadContext = createContext<UploadVideoContextProps | undefined>(
  undefined,
);

export const useCampaignVideoUpload = () => {
  const context = useContext(VideoUploadContext);

  if (!context) {
    throw new Error(
      "useCampaignVideoUpload must be used within a VideoUploadProvider",
    );
  }
  return context;
};

export const VideoUploadProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isUploadCompleted, setIsUploadCompleted] = useState<boolean>(false);
  const [isVideoUploading, setIsVideoUploading] = useState<boolean>(false);
  const uploadFileRef = useRef<File | null>(null);

  const { formData, setFormData } = useCreateCampaignForm();
  const mutation = useSaveVideoLink(
    (error: Error) => {},
    (data: unknown) => {
      setIsVideoUploading(false);
      setFormData((prev) => ({
        ...prev,
        cover: uploadFileRef.current,
      }));
      setTimeout(() => {
        setIsUploadCompleted(true);
      }, 1000);
    },
  );
  const onUploadComplete = (url: string) => {
    mutation.mutate({ link: url, id: formData.id });
  };

  const { uploadProgress, uploadUrl, uploadError, uploadVideo, cancelUpload } =
    useCloudinaryVideoUpload(onUploadComplete);
  const onUploadVideo = (file: File) => {
    if (file === null) return;

    if (uploadFileRef.current && uploadFileRef.current.name === file.name)
      return;

    uploadFileRef.current = file;
    setIsVideoUploading(true);
    setIsUploadCompleted(false);
    uploadVideo(file);
  };

  const onCancelUpload = () => cancelUpload();

  const onRetry = () => {
    if (uploadUrl) {
      mutation.mutate({ link: uploadUrl, id: formData.id });
      return;
    }
    if (uploadFileRef.current) {
      onUploadVideo(uploadFileRef.current);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isVideoUploading) {
        event.preventDefault();
        event.returnValue =
          "An upload is in progress. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isVideoUploading]);

  return (
    <VideoUploadContext.Provider
      value={{
        uploadProgress,
        uploadUrl,
        uploadError,
        onUploadVideo,
        isVideoUploading,
      }}
    >
      {children}

      {uploadProgress > 0 && !isUploadCompleted && (
        <div className="animate-fadeIn">
          <KroVideoUploadProgress
            onCancel={onCancelUpload}
            onRetry={onRetry}
            uploadProgress={uploadProgress}
            uploadUrl={uploadUrl}
            uploadError={uploadError ?? mutation.error?.message ?? null}
          />
        </div>
      )}
    </VideoUploadContext.Provider>
  );
};
