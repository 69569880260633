import React from "react";
import { KroButton } from "../buttons/kroButton";

export enum LoaderType {
  FIXED,
  OVERLAY,
}

interface KroLoadingIndicatorProps {
  error?: string;
  onRetry?: () => void;
  bg?: string;
  heightPX?: number;
  type?: LoaderType;
  showLogo?: boolean;
}

const KroLoadingIndicator: React.FC<KroLoadingIndicatorProps> = ({
  error,
  bg,
  onRetry,
  heightPX,
  showLogo,

  type = LoaderType.OVERLAY,
}) => {
  switch (type) {
    case LoaderType.FIXED:
      return (
        <div
          className={`flex ${heightPX ? `h-[${heightPX.toString()}px]` : "h-[calc(100vh-200px)]"} flex-col items-center justify-center ${bg ? bg : "bg-white"}`}
        >
          {error ? (
            <>
              <div className="mb-4 text-H_8 text-white">{error}</div>
              {onRetry && (
                <KroButton onclick={onRetry} title="Retry"></KroButton>
              )}
            </>
          ) : (
            <>
              <div className="loader mb-1"></div>
              {showLogo && (
                <img
                  src="/assets/icons/giv_logo.svg"
                  alt="Giv Logo"
                  className="h-24 w-24 animate-pulse"
                />
              )}
            </>
          )}
        </div>
      );
    case LoaderType.OVERLAY:
      return (
        <div
          className={`fixed inset-0 z-50 flex flex-col items-center justify-center ${bg ? bg : "bg-black bg-opacity-75"}`}
        >
          {error ? (
            <>
              <div className="mb-4 text-H_8 text-white">{error}</div>
              {onRetry && (
                <KroButton onclick={onRetry} title="Retry"></KroButton>
              )}
            </>
          ) : (
            <>
              <div className="loader mb-1"></div>
              <img
                src="/assets/icons/giv_logo.svg"
                alt="Giv Logo"
                className="h-24 w-24 animate-pulse"
              />
            </>
          )}
        </div>
      );
  }
};

export default KroLoadingIndicator;

// return (
//     <div
//       className={`fixed inset-0 z-50 flex flex-col items-center justify-center ${bg ? bg : "bg-black bg-opacity-75"}`}
//     >
//       {error ? (
//         <>
//           <div className="mb-4 text-H_8 text-white">{error}</div>
//           {onRetry && <KroButton onclick={onRetry} title="Retry"></KroButton>}
//         </>
//       ) : (
//         <>
//           <div className="loader mb-1"></div>
//           <img
//             src="/assets/icons/giv_logo.svg"
//             alt="Giv Logo"
//             className="h-24 w-24 animate-pulse"
//           />
//         </>
//       )}
//     </div>
//
