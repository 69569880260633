
import { useMutation } from "@tanstack/react-query";
import { AuthServiceImpl } from "features/onboarding/domain/services/authService";

const authService = new AuthServiceImpl();


export const useSendOTP = (
  onError:
    | ((
        error: Error,
        variables: IRequestOTP,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined,
  onSuccess:
    | ((
        data: IResponse,
        variables: IRequestOTP,
        context: unknown,
      ) => Promise<IResponse> | unknown)
    | undefined,
) => {
  return useMutation({
    mutationFn: (payload: IRequestOTP) => authService.requestOTP(payload),
    onError: onError,
    onSuccess: onSuccess,
  });
};

export const useVerifyOTP = (
  onError:
    | ((
        error: Error,
        variables: string,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined,
  onSuccess:
    | ((
        data: IResponse,
        variables: string,
        context: unknown,
      ) => Promise<IResponse> | unknown)
    | undefined,
) => {
  return useMutation({
    mutationFn: (token: string) => authService.verifyOTP(token),
    onError: onError,
    onSuccess: onSuccess,
  });
};