import Lottie from "react-lottie";
import animationData from "../../../common/assets/lottie/confetti_animation.json";
import { ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";

interface KroModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  maxWidth?: number;
  maxHeight?: number;
  closeOnOutsideClick?: boolean;
  showCloseButton?: boolean;
  className?: string;
  lottieAnimation?: boolean;
}

const KroModal: React.FC<KroModalProps> = ({
  isOpen,
  onClose,
  children,
  maxHeight,
  maxWidth,
  closeOnOutsideClick = true,
  showCloseButton = true,
  className = "p-[42px]",
  lottieAnimation = false,
}) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);

      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
      setTimeout(() => setIsVisible(false), 300);
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  if (!isVisible) return null;

  const handleOutsideClick = (e: React.MouseEvent) => {
    if (closeOnOutsideClick) {
      onClose();
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //TODO:Pop Up animation is janky

  return ReactDOM.createPortal(
    <div
      className={`fixed inset-0 flex items-end justify-center sm:items-center`}
      style={{ backgroundColor: "#00000099" }}
      onClick={handleOutsideClick}
    >
      {lottieAnimation && (
        <div className="absolute inset-0 z-10">
          <Lottie options={defaultOptions} height="100%" width="100%" />
        </div>
      )}
      <div
        className={`relative max-h-[95vh] w-full max-w-full overflow-hidden rounded-t-[20px] md:max-h-[${maxHeight}px] bg-white shadow-lg sm:max-w-[90%] sm:rounded-[20px] ${className} z-10 ${
          isOpen ? "modal-enter" : "modal-exit"
        }`}
        style={{ maxWidth: maxWidth }}
        onClick={(e) => e.stopPropagation()}
      >
        {showCloseButton && (
          <button
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            <img src="/assets/icons/close.svg" alt="close" />
          </button>
        )}

        <div
          className="overflow-y-auto"
          style={{ maxHeight: "calc(95vh - 0px)" }}
        >
          {children}
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default KroModal;

//Without pop up animation
// import Lottie from "react-lottie";
// import animationData from "../../../common/assets/lottie/confetti_animation.json";
// import { ReactNode, useEffect } from "react";
// import ReactDOM from "react-dom";

// interface KroModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   children: ReactNode;
//   maxWidth?: number;
//   maxHeight?: number;
//   closeOnOutsideClick?: boolean;
//   showCloseButton?: boolean;
//   className?: string;
//   lottieAnimation?: boolean;
// }

// const KroModal: React.FC<KroModalProps> = ({
//   isOpen,
//   onClose,
//   children,
//   maxHeight,
//   maxWidth,
//   closeOnOutsideClick = true,
//   showCloseButton = true,
//   className = "p-[42px]",
//   lottieAnimation = false,
// }) => {
//   useEffect(() => {
//     if (isOpen) {
//       document.body.classList.add("modal-open");
//     } else {
//       document.body.classList.remove("modal-open");
//     }

//     return () => {
//       document.body.classList.remove("modal-open");
//     };
//   }, [isOpen]);

//   if (!isOpen) return null;

//   const handleOutsideClick = (e: React.MouseEvent) => {
//     if (closeOnOutsideClick) {
//       onClose();
//     }
//   };

//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData,
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice",
//     },
//   };
//   return ReactDOM.createPortal(
//     <div
//       className="fixed inset-0 flex items-end justify-center sm:items-center"
//       style={{ backgroundColor: "#00000099" }}
//       onClick={handleOutsideClick}
//     >
//       {lottieAnimation && (
//         <div className="absolute inset-0 z-10">
//           <Lottie options={defaultOptions} height="100%" width="100%" />
//         </div>
//       )}
//       <div
//         className={`relative max-h-[95vh] w-full max-w-full overflow-hidden rounded-t-[20px] md:max-h-[${maxHeight}px] bg-white shadow-lg sm:max-w-[90%] sm:rounded-[20px] ${className} z-10`}
//         style={{ maxWidth: maxWidth }}
//         onClick={(e) => e.stopPropagation()}
//       >
//         {showCloseButton && (
//           <button
//             className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
//             onClick={onClose}
//           >
//             <img src="/assets/icons/close.svg" alt="close" />
//           </button>
//         )}

//         <div
//           className="overflow-y-auto"
//           style={{ maxHeight: "calc(95vh - 0px)" }}
//         >
//           {children}
//         </div>
//       </div>
//     </div>,
//     document.body,
//   );
// };
// export default KroModal;
