import React from "react";

function CampaignCard(props: CampaignCardProps) {
  return (
    <button
      onClick={() => props.onSelected()}
      className={`w-full ${
        props.selected ? "bg-positive-50" : "bg-white hover:bg-gray-100"
      } mt-8 rounded-[20px] border-2 px-4 ${
        props.selected ? "border-positive-600" : "border-neutral-400"
      } flex flex-row items-center justify-between gap-2`}
    >
      <div className="my-[26px] flex flex-1 flex-col items-start justify-start">
        <h4
          className={`text-left text-[20px] font-medium leading-[24px] tracking-[-0.005em] mb-1${
            props.selected ? "text-positive-900" : "text-black"
          }`}
          style={{
            fontFamily: "Inter",
          }}
        >
          {props.title}
        </h4>
        {props.subtitle && (
          <p
            className={`text-left text-[14px] font-normal leading-[22px] tracking-[-0.005em] md:block ${
              props.selected ? "text-positive-700" : "text-[#737373]"
            }`}
          >
            {props.subtitle}
          </p>
        )}
      </div>
      <img src={props.iconPath} alt={props.icon} className="self-center" />
    </button>
  );
}

interface CampaignCardProps {
  title: string;
  subtitle?: string;
  icon: string;
  selected: boolean;
  iconPath: string;
  onSelected: () => void;
}

export default CampaignCard;
