export interface IndividualResponse {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  token:string;
}

export interface IndividualResponseMapper {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  jwtToken:string;
}

export function mapIndividualResponse(
  data: IndividualResponse
): IndividualResponseMapper {
  return {
    jwtToken:data.token,
    id: data.id,
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
  };
}