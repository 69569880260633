import { useQuery } from '@tanstack/react-query';
import { CampaignServiceImpl } from 'features/campaign/domain/services/campaignService';

const campaignService = new CampaignServiceImpl();

export const useFetchCampaignCategories = () => {
  const {data: categories, isLoading, isError,error} =  useQuery({
    queryKey: ['campaignCategories'],
    queryFn: () => campaignService.fetchCampaignCategories()})

    return {
        categories,
        isLoading, 
        error,
        isError
    }
};
