import { formatDistanceToNow, isToday, isYesterday } from "date-fns";

export const formatRelativeDate = (date: Date) => {
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else {
    return formatDistanceToNow(date, { addSuffix: true });
  }
};
