import React from "react";
import useFetchActivityLog from "../../hooks/useFetchActivityLog";
import { useParams } from "react-router";

const donations = [
  {
    donor: "Tega Odia",
    new: true,
    comment: "Renovating a Hospital for Ifako Ijaye Local Govt Area",
    amount: "3,500 NGN",
    date: "12:00am",
    method: "Bank Transfer",
  },
  {
    donor: "Daniel D.",
    comment: "Renovating a Hospital for Ifako Ijaye Local Govt Area",
    amount: "100,250 EUR",
    date: "Yesterday, 12:00 AM",
    method: "PayPal",
  },
  {
    donor: "Bright A.",
    comment: "Renovating a Hospital for Ifako Ijaye Local Govt Area",
    amount: "10,500 ZAR",
    date: "30 Jan, 4:00 PM",
    method: "Monzo",
  },
  {
    donor: "Aniekan A.",
    comment: "Renovating a Hospital for Ifako Ijaye Local Govt Area",
    amount: "60,000 USD",
    date: "12 Sept, 11:00 PM",
    method: "Revolut",
  },
  {
    donor: "Andre J.",
    comment: "Renovating a Hospital for Ifako Ijaye Local Govt Area",
    amount: "10,500 ZAR",
    date: "12 Sept",
    method: "M-Pesa",
  },
  {
    donor: "Yemisi A.",
    comment: "Renovating a Hospital for Ifako Ijaye Local Govt Area",
    amount: "10,500 USD",
    date: "12 Sept",
    method: "PayPal",
  },
];
function DonationsTable() {
  const { id: campaignId } = useParams<{ id: string }>();

  const { campaigns } = useFetchActivityLog(campaignId ?? "");

  console.log(campaigns);

  return (
    <div className="mx-auto pl-5">
      <div className="overflow-x-auto rounded-lg">
        <table className="w-full border-collapse">
          <thead className="h-[36px]">
            <tr className="text-sm bg-weak-600 text-left text-sub-600">
              <th className="pl-6 text-body-sm font-thin text-sub-600">
                Donor
              </th>
              <th className="hidden text-body-sm font-thin text-sub-600 sm:table-cell">
                Comment
              </th>
              <th className="text-body-sm font-thin text-sub-600">Amount</th>
              <th className="text-body-sm font-thin text-sub-600">
                Date & Time
              </th>
              <th className="hidden text-body-sm font-thin text-sub-600 sm:table-cell">
                Payment Method
              </th>
              <th className="text-body-sm font-thin text-sub-600"></th>
            </tr>
          </thead>
          <tbody>
            {donations.map((donation, index) => (
              <tr key={index} className="border-b hover:bg-gray-50">
                <td className="mb-5 ml-4 mr-4 mt-6 flex items-center pl-2">
                  <p className="text-strong-600 text-body-sm">
                    {donation.donor}
                  </p>
                  {donation.new && (
                    <span className="ml-2 h-[16px] items-center rounded bg-warning-50 px-2 text-body-xs font-semibold text-warning-700">
                      NEW
                    </span>
                  )}
                </td>
                <td className="text-strong-600 hidden w-[325px] text-body-sm sm:table-cell">
                  {donation.comment}
                </td>
                <td className="text-strong-600 w-[180px] text-body-sm">
                  {donation.amount}
                </td>
                <td className="text-strong-600 text-body-sm">
                  {donation.date}
                </td>
                <td className="text-strong-600 hidden text-body-sm sm:table-cell">
                  {donation.method}
                </td>
                <td>
                  {donation.new ? (
                    <button className="flex w-[116px] flex-row items-center justify-center gap-2 rounded-[20px] bg-weak-600 pb-2 pl-2 pr-2 pt-2 lg:rounded-lg">
                      <p className="text-body-sm font-medium text-strong-950">
                        Say thanks
                      </p>
                      <img
                        src="/assets/icons/heart-line.svg"
                        alt="heart icon"
                      />
                    </button>
                  ) : (
                    <button className="w-[94px] rounded-[20px] border border-stroke-soft-200 pb-1 pl-2 pr-2 pt-1 text-body-sm text-sub-600 hover:text-blue-700 lg:rounded-lg">
                      Say thanks
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DonationsTable;
