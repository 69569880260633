import { useRef, useState } from "react";
import { KroUploadCardType } from "features/campaign/domain/enums/kroUploadCardType.enum";

const KroUploadCard = (props: KroUploadCardTypeProps) => {
  const imageUrl = () => {
    try {
      if (typeof props.value === "string") {
        return { url: props.value, file: null };
      }
      return props.value
        ? { url: URL.createObjectURL(props.value), file: props.value }
        : null;
    } catch (error) {
      return null;
    }
  };
  const [imagePreview, setImagePreview] = useState<{
    url: string;
    file: File | null;
  } | null>(imageUrl);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    if (file) {
      const fileUrl = URL.createObjectURL(file);

      setImagePreview({ url: fileUrl, file });
      props.onFileSelect(file);
    }
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  let uploadCard;

  switch (props.type) {
    case KroUploadCardType.LARGE:
      uploadCard = (
        <div
          onClick={imagePreview == null ? handleClick : () => {}}
          className={`flex flex-col items-center justify-center rounded-md border border-neutral-200 bg-neutral-50 ${props.className} ${
            imagePreview?.file?.type.startsWith("video/")
              ? "h-[360px]"
              : "h-[250px]"
          }`}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            if (
              file &&
              (file.type.startsWith("image/") || file.type.startsWith("video/"))
            ) {
              const fileUrl = URL.createObjectURL(file);
              setImagePreview({ url: fileUrl, file });
              props.onFileSelect(file);
            }
          }}
        >
          <input
            ref={inputRef}
            type="file"
            accept={props.allowVideo ? "image/*,video/*" : "image/*"}
            className="hidden"
            onChange={handleFileChange}
            id={props.id}
            name={props.name}
          />

          {imagePreview ? (
            <div className="relative h-full w-full">
              {imagePreview.file?.type.startsWith("video/") ? (
                <video
                  src={imagePreview.url}
                  controls
                  className="h-full w-full rounded-md object-cover"
                />
              ) : (
                <img
                  src={imagePreview.url}
                  alt="Uploaded preview"
                  className="h-full w-full rounded-md object-cover"
                />
              )}
              <button
                onClick={() => setImagePreview(null)}
                className="absolute right-5 top-5"
              >
                <img src="/assets/icons/delete.svg" alt="Remove" />
              </button>
            </div>
          ) : (
            <>
              <img
                className="mt-20 rounded-md"
                src="/assets/icons/image-add-line.svg"
                alt="add icon"
              />
              <label className="font-500 mt-2 text-body-md text-neutral-500">
                Cover photo or video
              </label>
              <label
                htmlFor="file-upload"
                className="font-500 mb-20 font-inter text-body-sm text-neutral-500"
              >
                Drag or upload your photo or video here
              </label>
            </>
          )}
        </div>
      );
      break;
    case KroUploadCardType.SMALL:
      uploadCard = (
        <div
          onClick={handleClick}
          className="flex h-[100px] w-[110px] flex-col items-center rounded-md border border-neutral-200 bg-neutral-50"
        >
          <input
            type="file"
            ref={inputRef}
            accept="image/*"
            className="hidden"
            onChange={handleFileChange}
            id={props.id}
            name={props.name}
          />

          {imagePreview ? (
            <div className="relative h-full w-full">
              <img
                src={imagePreview.url}
                alt="Uploaded preview"
                className="h-full w-full rounded-md object-cover"
              />
              <button
                onClick={() => setImagePreview(null)}
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
              >
                <img src="/assets/icons/delete.svg" alt="Remove" />
              </button>
            </div>
          ) : (
            <img
              className="mb-10 mt-10"
              src="/assets/icons/image-add-line.svg"
              alt="add icon"
            />
          )}
        </div>
      );
      break;

    default:
      break;
  }

  return <div>{uploadCard}</div>;
};

interface KroUploadCardTypeProps {
  type: KroUploadCardType;
  value: File | null | string;
  className?: string;
  id?: string;
  allowVideo?: boolean;
  name?: string;
  onFileSelect: (filePath: File) => void;
}

export default KroUploadCard;
