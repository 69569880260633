export const CampaignCounterComponent = (
  props: CampaignCounterComponentProps,
) => {
  const marginBottom = props.title;
  return (
    <div
      className={`xxs:block ${marginBottom ? "mb-9" : ""} flex w-full max-w-[606px] flex-col xs:block sm:block`}
    >
      <p className="text-H_9 text-neutral-500 md:hidden">
        {props.pageCount} of 4
      </p>
      <div className="flex flex-row items-center justify-between">
        {props.title && (
          <p className="text-H_8 font-medium text-black md:text-[32px]">
            {props.title}
          </p>
        )}

        {props.showSkip && (
          <button
            onClick={props.onSkipClicked}
            className="font-400 mt-3 text-body-lg leading-[20px] tracking-tight text-brand-dark_green"
          >
            Skip
          </button>
        )}
      </div>
      <span className="font-medium-regular text-netrual-500 text-body-md">
        {props.description}
      </span>
    </div>
  );
};

interface CampaignCounterComponentProps {
  pageCount: number;
  title?: string;
  showSkip: boolean;
  description?: string;
  onSkipClicked?: () => void;
}

export default CampaignCounterComponent;
