import { useMutation } from '@tanstack/react-query';
import { FileUploadResponse } from 'features/campaign/domain/models/fileUploadResponse';
import { SaveVideoPayload } from 'features/campaign/domain/models/saveVideoPayload';
import { CampaignServiceImpl } from 'features/campaign/domain/services/campaignService';

const campaignService = new CampaignServiceImpl();

export const useUploadFilesAndSaveVideoLink = (
  onError: ((error: Error, variables: { payload: SaveVideoPayload; files: File[] }, context: unknown) => Promise<unknown> | unknown) | undefined, 
  onSuccess: ((data: boolean, variables: { payload: SaveVideoPayload; files: File[] }, context: unknown) => Promise<FileUploadResponse> | unknown) | undefined
) => {
  return useMutation({
    mutationFn: ({ payload, files }: { payload: SaveVideoPayload; files: Array<File> }) => 
      campaignService.handleSaveVideoLinkAndUploadFiles(payload, files),
    onError: onError,
    onSuccess: onSuccess,
  });
};



export const useSaveVideoLink = (
  onError: ((error: Error, variables: SaveVideoPayload, context: unknown) => Promise<unknown> | unknown) | undefined, 
  onSuccess: ((data: boolean, variables: SaveVideoPayload, context: unknown) => Promise<boolean> | unknown) | undefined
) => {
  return useMutation({
    mutationFn: ( payload: SaveVideoPayload) => 
      campaignService.handleSaveVideoLink(payload),
    onError: onError,
    onSuccess: onSuccess,
  });
};
