import KroClient from "common/io/client/kroClient";
import { KroEndpoints } from "common/io/endpoints";
import { ISignInPayload } from "../models/signInPayload";
import { SignInResponseData, userModelFromObject } from "../models/signInResponse";

const AuthRepository = () => {
  const createIndividualAccount = async (payload: ICreateIndividualAccountPayload) => {
    try {
      return await KroClient.post(
        KroEndpoints.createIndividualAccount,
        payload,
      );
    } catch (error) {
      throw error;
    }
  };

  const sendOTP = async (payload: IRequestOTP): Promise<IResponse> => {
    try {
      return await KroClient.post(KroEndpoints.sendOTP, payload);
    } catch (error) {
      throw error;
    }
  };

  const signIn = async (payload: ISignInPayload): Promise<SignInResponseData> => {
    try {
      const response= await KroClient.post(KroEndpoints.signIn, payload);

            return {
              ...response.data, 
              user: userModelFromObject(response.data.user)
            }

    } catch (error) {
      throw error;
    }
  }


  const verifyOTP = async (otp: string): Promise<IResponse> => {
    try {
      return await KroClient.post(KroEndpoints.verifyOTP, { "token": otp });
    } catch (error) {
      throw error;
    }
  };


  const createOrganizationAccount = async (payload: ICreateOrganisationAccountPayload) => {
    try {
      return await KroClient.post(
        KroEndpoints.createOgranizationAccount,
        payload,
      );
    } catch (error) {
      throw error
    }
  }

  const getAllCategories = async () => {
    try {
      const response = await KroClient.get(
        KroEndpoints.getAllCategories,
      );
      return response;
    } catch (error) {
      throw error
    }
  }

  const saveOrganizationStepOne = async (id: string, payload: IOrganizationStepOnePayload) => {
    try {
      return await KroClient.patch(
        KroEndpoints.saveOrganizationStepOne(id),
        payload,
      );
    } catch (error) {
      throw error
    }
  }


  const saveOrganizationStepTwo = async (id: string, payload: IOrganizationStepTwoPayload) => {
    try {

      let formData = new FormData();
      formData.append("is_organisation_registered", payload.is_organisation_registered.toString())

      if (payload.registration_number && payload.file) {

        formData.append("registration_number", payload.registration_number || "")
          formData.append("file", payload.file, payload.file.name);
       

      }

      return await KroClient.patch(
        KroEndpoints.saveOrganizationStepTwo(id),
        formData,
      );
    } catch (error) {
      throw error
    }
  }



  return {
    signIn,
    createIndividualAccount,
    sendOTP,
    createOrganizationAccount,
    getAllCategories,
    saveOrganizationStepOne,
    saveOrganizationStepTwo,
    verifyOTP,
  };
};

export default AuthRepository;
