import { useMutation } from "@tanstack/react-query";
import { WithdrawDonationModel } from "features/dashboard/domain/model/withdrawDonationPayload";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";

const DashboardService = new DashboardServiceImpl();

const useWithdrawCampaignDonation = (
    onError: ((error: Error, variables: WithdrawDonationModel, context: unknown) => Promise<unknown> | unknown) | undefined, 
    onSuccess: ((data: unknown, variables: WithdrawDonationModel, context: unknown) => Promise<unknown> | unknown) | undefined
) => {
    return useMutation({
        mutationFn: (payload: WithdrawDonationModel) => DashboardService.withdrawCampaignDonation(payload),
        onError: onError,
        onSuccess: onSuccess,
    });
};

export default useWithdrawCampaignDonation;