import STORAGE_KEYS, { getStorageItem } from "common/utils/localStorage";
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import Routes from "router/routes";

interface FormContextType {
  formData: Record<string, any>;
  setFormData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  validateStepAccess: (currentStep: string) => void;
}

const CreateCampaignFormContext = createContext<FormContextType>({
  formData: {},
  setFormData: () => {},
  validateStepAccess: () => {},
});

export const useCreateCampaignForm = () =>
  useContext(CreateCampaignFormContext);

interface CreateCampaignFormProviderProps {
  children: ReactNode;
}

export const CreateCampaignFormProvider = ({
  children,
}: CreateCampaignFormProviderProps) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<Record<string, any>>(() => {
    try {
      const savedData = getStorageItem(STORAGE_KEYS.CREATE_CAMPAIGN_FORM_DATA);
      if (!savedData) return {};
      const parsedData = JSON.parse(savedData);
      return typeof parsedData === "object" && parsedData !== null
        ? parsedData
        : {};
    } catch (error) {
      localStorage.removeItem(STORAGE_KEYS.CREATE_CAMPAIGN_FORM_DATA);

      return {};
    }
  });
  useEffect(() => {
    const FilteredForm = Object.fromEntries(
      Object.entries(formData).filter(([k, v]) => !(v instanceof File)),
    );

    localStorage.setItem(
      STORAGE_KEYS.CREATE_CAMPAIGN_FORM_DATA,
      JSON.stringify(FilteredForm),
    );
  }, [formData]);

  const validateStepAccess = (currentStep: string) => {
    const sanitizedStep = currentStep.endsWith("/")
      ? currentStep.slice(0, -1)
      : currentStep;

    if (!formData && sanitizedStep !== Routes.CREATE_CAMPAIGN) {
      navigate(Routes.CREATE_CAMPAIGN);
      return;
    }

    if (sanitizedStep === Routes.CREATE_CAMPAIGN) return;
    if (!formData.id || formData.isPublished) {
      navigate(Routes.CREATE_CAMPAIGN);
    }
  };

  return (
    <CreateCampaignFormContext.Provider
      value={{
        formData,
        setFormData,
        validateStepAccess,
      }}
    >
      {children}
    </CreateCampaignFormContext.Provider>
  );
};
