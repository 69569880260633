import { useNavigate } from "react-router-dom";
import Routes from "router/routes";

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <div className="animate-fade-in mx-auto max-w-[1280px] lg:mt-[100px] lg:h-[600px]">
      <div className="flex flex-col items-center lg:flex-row lg:items-start">
        <div className="w-[358px] lg:w-[710px]">
          <h1 className="text-4xl leading-none pt-8 text-center font-dm-sans text-[48px] font-medium tracking-ultralTight text-neutral-900 lg:text-left lg:text-[100px] lg:font-medium lg:leading-[120px] lg:tracking-[-0.90%]">
            The platform for charity & contributions
          </h1>
          <div className="flex justify-center lg:justify-start lg:pt-16">
            <button
              onClick={() => navigate(Routes.CREATE_CAMPAIGN)}
              className="hidden h-[72px] w-[245px] transform items-center justify-center rounded-[60px] bg-[#022928] text-white transition-transform hover:scale-105 active:scale-95 lg:flex"
            >
              <span className="mr-2">Start Fundraising</span>
              <img
                className="h-5 w-5"
                src="/assets/icons/Arrow.svg"
                alt="arrow"
              />
            </button>
          </div>
        </div>

        <div className="mt-6 lg:ml-12 lg:mt-0 lg:w-auto">
          <img
            src="/assets/images/site-right-display.svg"
            alt="Site Display"
            className="w-full lg:h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
