import { formatCurrency } from "common/utils/currencyFormater";
import KroAmountInputField from "features/components/inputs/KroAmountInput";
import useFetchBanks from "../../hooks/useFetchBanks";
import { useSnackbar } from "common/context/snackbarContext";
import { KroDropdownT } from "features/components/dropdowns/kroDropdown";
import { useForm } from "@tanstack/react-form";
import Validation from "common/utils/validation";
import KroTextField from "features/components/inputs/kroTextField";
import {
  KroInputFieldType,
  kroInputType,
} from "features/campaign/domain/enums/kroInputFieldType.enum";
import { KroButton } from "features/components/buttons/kroButton";
import useFetchBankAccountInfo from "../../hooks/useFetchBankAccountInfo";
import { useEffect } from "react";
import { BankModel } from "features/dashboard/domain/model/bankModel";
import { WithdrawDonationPayload } from "features/dashboard/domain/model/withdrawDonationPayload";

interface WithdrawDetailsProps {
  campaignId: string;
  withdrawalAmount: number;
  onClose?: (payload?: WithdrawDonationPayload) => void;
}

interface WithdrawalConfirmationForm {
  bankName: BankModel | null;
  accountNumber: string;
  accountHolderName: string | null;
}

const WithdrawalConfirmation: React.FC<WithdrawDetailsProps> = ({
  campaignId,
  onClose,
  withdrawalAmount,
}) => {
  useEffect(() => {
    form.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { banks, error: errorFetchingBanks } = useFetchBanks();
  const { showSnackbar } = useSnackbar();
  const validateAccountNumber = async (
    accountNumber: string,
    bankCode: string,
  ): Promise<string | undefined> => {
    try {
      await mutation.mutateAsync({
        accountNumber,
        bankCode,
      });

      return undefined;
    } catch (error) {
      if (error instanceof Error) {
        return error.message;
      }
    }
  };
  const mutation = useFetchBankAccountInfo(
    (_) => {},
    (data) => {
      form.setFieldValue("accountHolderName", data.accountHolderName);
    },
  );
  const form = useForm<WithdrawalConfirmationForm>({
    defaultValues: {
      bankName: null,
      accountHolderName: null,
      accountNumber: "",
    },
    onSubmit(form) {
      const { accountHolderName, accountNumber, bankName } = form.value;
      onClose?.({
        bank_owner_full_name: accountHolderName ?? "",
        account_number: accountNumber ?? "",
        account_bank: bankName?.name ?? "",
      });
    },
  });
  const { bankName, accountHolderName } = form.useStore(
    (state) => state.values,
  );

  if (errorFetchingBanks) {
    showSnackbar(errorFetchingBanks.message);
    onClose?.();
  }

  return (
    <div className="mx-5 lg:mx-10">
      <header className="mb-[18px] mt-[26px] w-full">
        <h2 className="text-left font-inter text-[20px] font-medium leading-[20px] tracking-[-0.006em]">
          Deposit Account
        </h2>
      </header>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        className="flex w-full flex-col pb-5 pt-6"
      >
        <KroAmountInputField
          title={"You’re withdrawing"}
          currency={"₦"}
          value={formatCurrency(withdrawalAmount)}
          readOnly={true}
          className="rounded-[20px] border border-neutral-200 bg-neutral-50 px-3"
          placeHolder={""}
          onChange={() => {}}
        ></KroAmountInputField>

        <div className="my-6 h-1 border-b border-[#E1E4EA]"></div>

        <form.Field
          name="bankName"
          validators={{
            onChange: ({ value }) => Validation.required(value),
          }}
        >
          {(field) => (
            <KroDropdownT<BankModel>
              label={"Select Bank"}
              hoverType="fixed"
              errorMessage={field.state.meta.errors.join(", ")}
              placeHolder={"Select your bank"}
              selectedValue={field.state.value}
              className={"mb-6"}
              options={
                banks?.map((bank) => ({
                  value: bank,
                  name: bank.name,
                })) ?? []
              }
              onDropdownItemClicked={(option: BankModel) =>
                field.handleChange(option)
              }
            />
          )}
        </form.Field>
        <form.Field
          name="accountNumber"
          validators={{
            // onChange: ({ value }) => Validation.required(value?.toString()),
            onChangeAsyncDebounceMs: 1000,
            onChangeAsync: ({ value }) => {
              if (!value) return Validation.required(value?.toString());

              if (value.length < 10) return "Account number is too short";
              return validateAccountNumber(
                value?.toString() ?? "",
                bankName?.code ?? "",
              );
            },
          }}
        >
          {(field) => (
            <KroTextField
              leadingPadding="pl-10"
              leadingAddon={
                <img src="/assets/icons/bank_icon.svg" alt="bank" />
              }
              addon={
                mutation.isPending && (
                  <img
                    src="/assets/icons/loading_icon.svg"
                    className="animate-spin"
                    alt="loading"
                  />
                )
              }
              errorMessage={field.state.meta.errors.join(", ")}
              isDisabled={!bankName || mutation.isPending}
              id={field.name}
              onChange={(e) => {
                if (accountHolderName)
                  form.setFieldValue("accountHolderName", null);
                return field.handleChange(e.target.value);
              }}
              name={field.name}
              label={"Account Number"}
              placeHolder={"xxxx-xx-xxxx"}
              inputType={kroInputType.NUMBER}
              className={""}
              type={KroInputFieldType.TEXTADDONS}
            />
          )}
        </form.Field>
        {accountHolderName && (
          <KroTextField
            leadingPadding="pl-10"
            leadingAddon={
              <img src="/assets/icons/user-6-line.svg" alt="bank"></img>
            }
            value={accountHolderName}
            label={"Beneficiary"}
            isDisabled={true}
            type={KroInputFieldType.TEXTADDONS}
            placeHolder={""}
          ></KroTextField>
        )}
        <form.Subscribe
          // selector={(state) => [state.canSubmit, state.isValidating]}
          children={({ canSubmit, isValidating }) => (
            <KroButton
              isDisabled={!canSubmit || isValidating}
              title="Withdraw"
              className="mb-2"
              type="submit"
            ></KroButton>
          )}
        />
      </form>
    </div>
  );
};

export default WithdrawalConfirmation;
