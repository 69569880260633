import { useQuery } from "@tanstack/react-query";
import { DashboardServiceImpl } from "features/dashboard/domain/services/dashboardService";

const DashboardService = new DashboardServiceImpl();

const useFetchUserCampaigns = () => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["fetchUserCampaign"],
    queryFn: () => DashboardService.fetchUserCampaigns(),
  });

  return { data, isLoading, error, refetch };
};

export default useFetchUserCampaigns;
