import { useSnackbar } from "common/context/snackbarContext";
import CampaignCard from "features/dashboard/presentation/components/dashboard/campaignCard";
import SectionHeader from "features/dashboard/presentation/components/dashboard/sectionHeader";
import { useFetchCampaignCategories } from "features/dashboard/presentation/hooks/useFetchCampaignCategories";
import useFetchCampaigns from "features/dashboard/presentation/hooks/useFetchCampaings";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Routes from "router/routes";

export const DiscoverCampaigns: FC = () => {
  const { error, refetch } = useFetchCampaignCategories();

  const navigate = useNavigate();

  const {
    campaigns,
    isLoading: isLoadingCampaign,
    error: errorLoadingCampaigns,
    refetch: refetchCampaigns,
  } = useFetchCampaigns();
  const { showActionSnackbar } = useSnackbar();
  if (error || errorLoadingCampaigns) {
    showActionSnackbar(
      error?.message ?? errorLoadingCampaigns?.message ?? "",
      "reload",
      refetch ?? refetchCampaigns,
    );
  }

  // const [selectedCategory, setSelectedCategory] =
  //   useState<CampaignCategoryModel | null>(null);
  return (
    <div className="mx-auto mt-[30px] w-full max-w-[1290px] lg:mt-[110px]">
      <SectionHeader title="Discover campaigns" />

      {/* <div className="flex flex-col items-end justify-between gap-5 sm:gap-0">
        <div className="flex flex-wrap gap-4">
          {isLoading
            ? [...Array(4)].map((_, index) => (
                <CustomButton.Skeleton key={index} />
              ))
            : categories?.map((category, index) => (
                <CustomButton
                  label={category.name}
                  key={index}
                  isSelected={
                    selectedCategory
                      ? selectedCategory._id === category._id
                      : undefined
                  }
                  onClick={() => setSelectedCategory(category)}
                />
              ))}
        </div>
        <div className="mt-3 flex items-center gap-3">
          <span className="font-inter text-body-lg font-normal leading-24 tracking-ultralTight text-[#404040]">
            Sort by:
          </span>
          <div className="relative">
            <button className="flex h-[52px] items-center justify-center gap-1 rounded-[32px] border border-[#E5E5E5] bg-neutral-50 px-6 py-[13.4px] font-inter text-body-lg font-medium leading-24 tracking-ultralTight text-[#0F0F0F] hover:bg-neutral-200">
              Just Launched{" "}
              <ChevronDownIcon
                className={`h-5 w-5 text-gray-500 transition-transform`}
              />
            </button>
          </div>
        </div>
      </div> */}
      <div className="mt-[36px] grid grid-cols-1 place-items-center gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {isLoadingCampaign
          ? [...Array(10)].map((_, index) => (
              <CampaignCard.Skeleton key={index} />
            ))
          : [...(campaigns ?? [])]
              ?.reverse()
              .map((campaign, index) => (
                <CampaignCard
                  key={index}
                  buttonTitle="Donate"
                  data={campaign}
                  onViewClick={() =>
                    campaign.id
                      ? navigate(Routes.CAMPAIGN_DETAILS(campaign.id))
                      : null
                  }
                />
              ))}
      </div>
    </div>
  );
};
