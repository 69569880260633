import { useQuery } from "@tanstack/react-query";
import { CampaignServiceImpl } from "features/campaign/domain/services/campaignService";

const campaignService = new CampaignServiceImpl();

const useFetchCampaign = (id:string) => {
  const { data, isLoading, error,refetch } = useQuery({
    queryKey: ["fetchCampaign", id],
    queryFn: () => campaignService.fetchCampaign(id),
  });

  return { data, isLoading, error,refetch };
};

export default useFetchCampaign;
